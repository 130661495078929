import React, { useState, useMemo, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import styled from "styled-components";
import _groupBy from "lodash.groupby";
import _chunk from "lodash.chunk";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useParams, Link, Redirect } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Layout, Input, Calendar, Rate, Empty, Alert, Modal } from "antd";
import { PageSkeleton } from "lib/components";
import { PROVIDER } from "lib/graphql/queries";
import { Provider as ListingData, ProviderVariables } from "lib/graphql/queries/Provider/__generated__/Provider";
import { useScrollToTop } from "lib/hooks";
import { Viewer } from "lib/types";
import moment, { Moment } from "moment";
import { ListingCreateBookingModal } from "./components";
import MapboxMap from "sections/Home/components/MapBox";
import { displaySuccessNotification,displayErrorMessage } from "lib/utils";
import { DeleteProvider, DeleteProviderVariables } from "lib/graphql/mutations/DeleteProvider/__generated__/DeleteProvider";
import { SetProviderRating, SetProviderRatingVariables } from "lib/graphql/mutations/SetProviderRating/__generated__/SetProviderRating";
import { AddComment, AddCommentVariables } from "lib/graphql/mutations/AddComment/__generated__/AddComment";
import { AddFavorite, AddFavoriteVariables } from "lib/graphql/mutations/AddFavorite/__generated__/AddFavorite";
import { DeleteFavorite, DeleteFavoriteVariables } from "lib/graphql/mutations/DeleteFavorite/__generated__/DeleteFavorite";
import { Provider_provider_bookings_result } from "lib/graphql/queries/Provider/__generated__/Provider";
import { DELETE_PROVIDER, SET_PROVIDER_RATING, ADD_COMMENT, ADD_FAVORITE, DELETE_FAVORITE } from "lib/graphql/mutations";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AID_ICONS } from "constants/aids";
import CalendarCell from "lib/components/CalendarCell";
import MarkerIcon from "sections/Home/imgs/icon.marker.svg";
import SquareIcon from "sections/Home/imgs/icon.area.svg";
import HeartIcon from "sections/Home/imgs/icon.heart.svg";
import HeartActiveIcon from "sections/Home/imgs/icon.heart.active.svg";
import ShareIcon from "sections/Home/imgs/icon.share.svg";
import DataIcon from "sections/Home/imgs/data-icon.svg";
import InfoIcon from "sections/Home/imgs/icon.info.2.svg";

interface MatchParams {
  id: string;
}

interface Props {
  viewer: Viewer;
}

const { Content } = Layout;
const PAGE_LIMIT = 5;

export const Listing = ({ viewer }: Props) => {
  const [checkInDate, setCheckInDate] = useState<Moment | null>(null);
  const [comment, setComment] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [commentsPage, setCommentsPage] = useState(1);
  const [showBookingsModal, setShowBookingsModal] = useState(false);
  const [calendarMode, setCalendarMode] = useState<"month" | "year">("month");
  const [activeBookings, setActiveBookings] = useState<Provider_provider_bookings_result[]>([]);
  const calendarRef = useRef<HTMLDivElement | null>(null);

  const { id } = useParams<MatchParams>();

  const { loading, data, error, refetch, called } = useQuery<
    ListingData,
    ProviderVariables
  >(PROVIDER, {
    variables: {
      id: +id,
      commentsPage,
      limit: PAGE_LIMIT,
    },
    fetchPolicy: 'no-cache',
  });

  const [deleteListing, { loading: deleteLoading, data: deleteData }] = useMutation<DeleteProvider, DeleteProviderVariables>(DELETE_PROVIDER, {
    onError: () => displayErrorMessage("Some error occurred while deleting the listing!"),
    onCompleted: () => displaySuccessNotification("You've successfully deleted your listing!"),
  });

  const [setRating] = useMutation<SetProviderRating, SetProviderRatingVariables>(SET_PROVIDER_RATING, {
    onError: () => displayErrorMessage("Some error occurred while setting the rating!"),
    onCompleted: () => refetch(),
  });

  const [addComment, { loading: commentLoading }] = useMutation<AddComment, AddCommentVariables>(ADD_COMMENT, {
    onError: () => displayErrorMessage("Some error occurred while adding the comment!"),
    onCompleted: () => {
      refetch();
      setComment('');
    },
  });

  const [addFavorite] = useMutation<AddFavorite, AddFavoriteVariables>(ADD_FAVORITE, {
    onError: () => displayErrorMessage("Some error occurred while changing favorite status!"),
    onCompleted: () => refetch(),
  });

  const [deleteFavorite] = useMutation<DeleteFavorite, DeleteFavoriteVariables>(DELETE_FAVORITE, {
    onError: () => displayErrorMessage("Some error occurred while changing favorite status!"),
    onCompleted: () => refetch(),
  });

  const marker = useMemo(() => (data && data.provider ? { lng: data.provider.longitude, lat: data.provider.latitude } : null), [data]);
  const maxCommentPages = useMemo(() => ((data && data.provider && Math.ceil(data.provider.comments.total / PAGE_LIMIT)) || 0), [data]);
  const aids = useMemo(() => {
    if (data && data.provider && data.provider.aids) {
      return _groupBy(data.provider.aids.map(a => a.details).filter(a => a.parentId !== a.id), 'parentName');
    }
    return {};
  }, [data]);
  const viewerIsHost = viewer.id === (data && data.provider && data.provider.user.id);

  useEffect(() => {
    refetch();
  }, [commentsPage, refetch]);

  useScrollToTop();

  const clearBookingData = () => {
    setModalVisible(false);
    setCheckInDate(null);
  };

  const handleListingRefetch = async () => {
    await refetch();
  };

  if (deleteData && deleteData.deleteProvider) {
    return <Redirect to={`/user/${viewer.id}`} />;
  }

  if (loading && !data) {
    return (
      <Content className="listings">
        <PageSkeleton />
      </Content>
    );
  }

  if (error) {
    return (
      <Content className="listings">
        <Alert
          message="Uh oh! Something went wrong :("
          description="This listing may not exist or we've encountered an error. Please try again soon!"
          type="error"
          showIcon
        />
      </Content>
    );
  }

  const listing = data ? data.provider : null;

  if (called && !loading && !listing) {
    return (
      <Content className="listings content-center" >
        <Empty description="Listing is not found" />
      </Content>
    );
  }

  if (!listing) {
    return null;
  }

  const listingCreateBookingModalElement =
    listing && checkInDate ? (
      <ListingCreateBookingModal
        id={listing.id}
        price={listing.capacity}
        modalVisible={modalVisible}
        checkInDate={checkInDate}
        setModalVisible={setModalVisible}
        clearBookingData={clearBookingData}
        handleListingRefetch={handleListingRefetch}
      />
    ) : null;

  const onCalendarSelect = (date: Moment | null) => {
    if (calendarMode === "year") return setCalendarMode("month");
    setCheckInDate(date);
    setModalVisible(true);
  }

  const onCalendarBookingSelect = (date: Moment | null) => {
    if (calendarMode === "year") return setCalendarMode("month");
    if (listing.bookings && listing.bookings.result && date) {
      const bookings = filterBookings(date, listing.bookings.result, 'day');
      setActiveBookings(bookings);
      setShowBookingsModal(true);
    }
  }

  const filterBookings = <T extends { date: string },>(date: Moment, bookings: T[], type: "day" | "month") => {
    const d1 = date.startOf('day').format(type === "day" ? "YYYY-MM-DD" : "YYYY-MM");
    return bookings.filter(b => {
      const d2 = moment.utc(b.date).startOf('day').format(type === "day" ? "YYYY-MM-DD" : "YYYY-MM");
      return d1 === d2;
    })
  }

  const scrollToCalenbar = () => {
    if (calendarRef.current) {
      calendarRef.current.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }

  return (
    <StyledListing>
      <div className="container">
        <ul className="breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li>
            <Link to={`/listings/${listing.city}`}>
              Collection points in&nbsp;
              {listing.city}
            </Link>
          </li>
          <li>
            <Link to={`/listing/${listing.id}`}>
              Collection point: {listing && listing.title}
            </Link>
          </li>
        </ul>
        <div className="hero">
          <div className="hero__container">
            <div className="hero__title__container">
              <div className="hero__title__title">
                <h1 className="hero__title-title">{listing && listing.title}</h1>
              </div>
              {listing.currentUser && (
                <div className="hero__title__buttons">
                  <SaveButton
                    active={listing.currentUser.isFavorite}
                    onClick={() => {
                      if (listing.currentUser!.isFavorite) {
                        deleteFavorite({ variables: { input: { id: listing.id } } });
                      } else {
                        addFavorite({ variables: { input: { id: listing.id } } });
                      }
                    }}
                  >
                    {listing.currentUser.isFavorite ? 'Remove' : "Save"}
                  </SaveButton>
                  {/* <div className="share">Share</div> */}
                </div>
              )}
            </div>
            <div className="hero__subtitle">
              <div className="hero__subtitle-location">{listing && listing.address}</div>
              {listing && (
                <div className="hero__subtitle-area">
                  {listing.capacity}
                  <span>{" "} m<sup>2</sup></span>
                </div>
              )}
              <div className="hero__title__btn">
                <span className="hero__title__btn-industrial">
                  <div className="hero__title__btn-text">{listing && listing.type}</div>
                </span>
              </div>
            </div>
            <div className="hero__host">
              <div className="hero__host-grey">Hosted by</div>
              <Link to={`/user/${listing.user.id}`}>
                <img src={listing ? listing.user.avatar : ""} alt="1" className="amazon-icon" />
                <div className="hero__host-black">{listing && listing.user.name}</div>
              </Link>
            </div>
            <div className="hero__imgs">

              <StyledSwiper
                pagination={true}
                modules={[Pagination]}
              >
                {listing.image.map(image => (
                  <SwiperSlide key={image} style={{ backgroundImage: `url("${image}")` }}></SwiperSlide>
                ))}
              </StyledSwiper>

              <div className="hero__liked">
                {viewerIsHost && (
                  <div
                    className="hero__img__button delete"
                    onClick={() => deleteListing({ variables: { input: { id: +id } } })}
                    >
                    <div className="hero__img__btn">
                      <div className="hero__img__btn-text">
                        {deleteLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Delete"}
                      </div>
                    </div>
                  </div>
                )}
                {viewerIsHost && (
                  <Link to={`/edit/${id}`} className="hero__img__button edit">
                    <div className="hero__img__btn">
                      <div className="hero__img__btn-text">Edit</div>
                    </div>
                  </Link>
                )}
                <div className="hero__img__button">
                  <div onClick={scrollToCalenbar} className="hero__img__btn">
                    <div className="hero__img__btn-text">
                      {viewerIsHost ? 'Show bookings' : 'Reserve date'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about__container">
            <div className="row">
              <div className="column">
                <div className="about__title">
                  <h2 className="about__title-title">About this space</h2>
                  <div>{listing && listing.description}</div>
                </div>
              </div>
              <div className="column">
                <div className="hours__title">
                  <h2 className="hours__title-title">Working hours: </h2>
                  <div className="hours__title-table">
                    {data && data.provider && data.provider.working_hours ? (
                      <>
                        <div className="work-days">
                          <div className="work-day">Sun:</div>
                          <div className="work-day">Mon:</div>
                          <div className="work-day">Tue:</div>
                          <div className="work-day">Wed:</div>
                          <div className="work-day">Thu:</div>
                          <div className="work-day">Fri:</div>
                          <div className="work-day">Sat:</div>
                        </div>
                        <div className="work-hour">
                          <div className="work-hours">
                            {data.provider.working_hours.sun ? (
                              data.provider.working_hours.sun.from +
                              ' - ' +
                              data.provider.working_hours.sun.to
                            ): '-'}
                          </div>
                          <div className="work-hours">
                            {data.provider.working_hours.mon ? (
                              data.provider.working_hours.mon.from +
                              ' - ' +
                              data.provider.working_hours.mon.to
                            ): '-'}
                          </div>
                          <div className="work-hours">
                            {data.provider.working_hours.tue ? (
                              data.provider.working_hours.tue.from +
                              ' - ' +
                              data.provider.working_hours.tue.to
                            ): '-'}
                          </div>
                          <div className="work-hours">
                            {data.provider.working_hours.wed ? (
                              data.provider.working_hours.wed.from +
                              ' - ' +
                              data.provider.working_hours.wed.to
                            ): '-'}
                          </div>
                          <div className="work-hours">
                            {data.provider.working_hours.thu ? (
                              data.provider.working_hours.thu.from +
                              ' - ' +
                              data.provider.working_hours.thu.to
                            ): '-'}
                          </div>
                          <div className="work-hours">
                            {data.provider.working_hours.fri ? (
                              data.provider.working_hours.fri.from +
                              ' - ' +
                              data.provider.working_hours.fri.to
                            ): '-'}
                          </div>
                          <div className="work-hours">
                            {data.provider.working_hours.sat ? (
                              data.provider.working_hours.sat.from +
                              ' - ' +
                              data.provider.working_hours.sat.to
                            ): '-'}
                          </div>
                        </div>
                      </>
                    ) : (
                      <Alert
                        type="warning"
                        message="Warning"
                        description="Information is not available"
                        showIcon
                      />
                    )}
                  </div>
                  {/* <a href="#1" className="hours-link">
                    <div>Write to us</div>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="list__things">
          <div className="list__things__container">
            <h3 className="list__things-title">
              Things you can help with
            </h3>
            {Object.keys(aids).length > 0 ? (
              <div className="list__things__priority">
                {Object.keys(aids).map(aidGroup => (
                  <React.Fragment key={aidGroup}>
                    <h4 className="priority-title">
                      {aidGroup}
                    </h4>
                    <div className="row">
                      {_chunk(aids[aidGroup], 4).map((aids, i) => (
                        <div
                          key={i}
                          className={`column ${i === 1 ? 'second' : i === 2 ? 'third' : ''}`}
                        >
                          {aids.map(aid => (
                            <div key={aid.id} className="priority-item">
                              <div className="priority-item-icon">
                                <img src={AID_ICONS[aid.id as keyof typeof AID_ICONS]} alt="1" />
                              </div>
                              <div className="priority-item-text">{aid.name}</div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <Alert
                type="warning"
                message="Warning"
                description="Information is not available"
                showIcon
              />
            )}

          </div>
        </div>
        <div className="reviews">
          <div className="reviews__container">
            <h5 className="reviews-title">Reviews</h5>
            <div className="reviews__area">
              {data && data.provider && data.provider.rating ? (
                <>
                  <div className="rating-area">
                    <Rate
                      value={data.provider.rating.current || 0} disabled={!viewer.id || viewerIsHost}
                      allowClear={false}
                      onChange={rating => {
                        setRating({ variables: { input: { id: listing.id, rating } } })
                      }}
                    />
                  </div>
                  <div className="rating__result">
                    <span className="avg-result">
                      {data.provider.rating.average}/5
                    </span>
                  </div>
                  <div className="reviews__counter">
                    <span>({data.provider.rating.count} reviews)</span>
                  </div>
                </>
              ) : (
                <Alert
                  type="warning"
                  message="Warning"
                  description="Rating information is not available"
                  showIcon
                />
              )}
            </div>

            <div className="reviews__comments">
              {data && data.provider && data.provider.comments.total ? (
                <>
                  {data.provider.comments.result.map(comment => (
                    <div className="reviews__comments__item" key={comment.id}>
                      <div className="reviews__comments-comment">
                        <div className="user__info">
                          <Link className="user-avatar" to={`/user/${comment.user.id}`}>
                            <img src={comment.user.avatar} alt="1" />
                          </Link>
                          <span>by</span>
                          <div className="username">{comment.user.name}</div>
                        </div>
                        <span className="posted-time">{moment.utc(comment.date).fromNow()}</span>
                      </div>
                      <div className="user-text">
                        <div>{comment.comment}</div>
                      </div>
                    </div>
                  ))}
                  <div className="pages__swiper">
                    <div
                      className="arrow"
                      onClick={() => {
                        if (commentsPage > 1) setCommentsPage(prev => prev - 1);
                      }}
                    >
                      <i className="left"></i>
                    </div>
                    <div className="pages__counter">
                      <span>{commentsPage} of {maxCommentPages}</span>
                    </div>
                    <div
                      className="arrow"
                      onClick={() => {
                        if (commentsPage < maxCommentPages) setCommentsPage(prev => prev + 1);
                      }}
                    >
                      <i className="right"></i>
                    </div>
                  </div>
                </>
              ) : (
                <Empty description="No comments added yet" />
              )}

              <div className="comment__area">
                <h5 className="comment-title">Leave a comment</h5>
                <Input.TextArea
                  placeholder="Type your text here"
                  rows={8}
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                  maxLength={255}
                />
                <div>
                  <button
                    className="send-bttn"
                    disabled={commentLoading || !comment}
                    onClick={() => {
                      addComment({ variables: { input: { id: listing.id, comment } } })
                    }}
                  >
                    {commentLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Send comment"}
                  </button>
                </div>
              </div>
            </div>

          </div>
          <div className="map__container">
            <h4 className="map__container-title">Location</h4>
            <div className="map">
              <MapboxMap type="item" markerPos={marker} showBorder={false} />
            </div>
          </div>
          {!viewer.id && (
            <div className="message__to-singin">
              <img src={InfoIcon} alt="1" className="warning-icon" />
              <div className="message-text">To reserve a date, <Link to="/login" className="sing-in">sing in</Link></div>
            </div>
          )}
          <div className="date__container" ref={calendarRef}>
            {!viewerIsHost ? (
              <>
                <h4 className="date__container-title">When do you plan to come?</h4>
                <div className="date__container-text">
                  Reserve a date is easy - just click on the date in the calendar, and then the "Reserve date" button will activate. By clicking on the button, you will receive an email with a code confirming the booking. Enter the code in the field
                  that appears. 
                </div>
              </>
            ) : (
              <>
                <h4 className="date__container-title">People reserved dates for humanitarian aid</h4>
              </>
            )}
 
            <div className="date__calendar">
            {viewerIsHost ? (
              <StyledCalendar
                mode={calendarMode}
                onPanelChange={(_, mode) => setCalendarMode(mode as any)}
                onSelect={date => onCalendarBookingSelect(date!)}
                monthCellRender={date => {
                  if (listing.bookings && listing.bookings.result) {
                    const bookings = filterBookings(date, listing.bookings.result, 'month');
                    return <CalendarCell images={bookings.map(b => b.user.avatar)} />
                  }
                  return null;
                }}
                dateCellRender={date => {
                  if (listing.bookings && listing.bookings.result) {
                    const bookings = filterBookings(date, listing.bookings.result, 'day');
                    return <CalendarCell images={bookings.map(b => b.user.avatar)} />
                  }
                  return null;
                }}
              />
            ) : (
              <StyledCalendar
                onSelect={date => onCalendarSelect(date!)}
                monthCellRender={date => {
                  if (listing.currentUser) {
                    const bookings = filterBookings(date, listing.currentUser.bookings, 'month');
                    return <CalendarCell images={bookings.map(() => viewer.avatar!)} />
                  }
                  return null;
                }}
                dateCellRender={date => {
                  if (listing.currentUser) {
                    const bookings = filterBookings(date, listing.currentUser.bookings, 'day');
                    return <CalendarCell images={bookings.map(() => viewer.avatar!)} />
                  }
                  return null;
                }}
                disabledDate={date => {
                  return (
                    !viewer.id
                      ||
                    (
                      listing.currentUser &&
                      filterBookings(date, listing.currentUser.bookings, 'day').length > 0
                    ) ||
                    date < moment().startOf('day')
                      ||
                    (
                      listing.working_hours &&
                      !(listing.working_hours as any)[date.format("ddd").toLocaleLowerCase()]
                    ) ||
                    false
                  )
                }}
              />
            )}
            </div>
          </div>

          <Modal
            centered
            footer={null}
            visible={showBookingsModal}
            onCancel={() => setShowBookingsModal(false)}
          >
            <StyledBookingModal>
              <OverlayScrollbarsComponent
                style={{ maxHeight: 400, margin: 15 }}
                options={{ scrollbars: { autoHide: 'scroll' } }}
              >
                {activeBookings.length ? activeBookings.map(b => (
                  <Link key={b.user.id} className="booking-item" to={`/user/${b.user.id}`}>
                    <img src={b.user.avatar} alt="avatar" />
                    <div className="name">{b.user.name}</div>
                  </Link>
                )) : (
                  <Empty description="No bookings for this date" />
                )}
              </OverlayScrollbarsComponent>
            </StyledBookingModal>
          </Modal>
        </div>
      </div>
      {listingCreateBookingModalElement}
    </StyledListing>
  );
};


const StyledCalendar = styled(Calendar)`

  .ant-fullcalendar-today .ant-fullcalendar-date {
    background: #e6f7ff !important;
  }

  .ant-fullcalendar-selected-day .ant-fullcalendar-date {
    background: inherit;
    &:hover {
      background: #e6f7ff;
    }
  }

  .ant-fullcalendar-selected-day .ant-fullcalendar-value {
    color: inherit;
  }

`;

const StyledBookingModal = styled.div`

  .booking-item {
    padding: 10px;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid #F2F2F2;
    }

    &:hover {
      background-color: #edf5fc70;
    }

    img {
      width: 45px;
      height: 45px;
      border: 2px solid #F2F2F2;
      border-radius: 50%;
      margin-right: 10px;
    }

    .name {
      font-family: 'Rubik';
      font-size: 18px;
      color: #02020B;
    }
  }

`;

const StyledSwiper = styled(Swiper)`
  aspect-ratio: 16 / 9;
  background: #364d79;
  border-radius: 10px;
  overflow: hidden;

  .swiper-slide {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

const SaveButton = styled.div<{ active: boolean }>`
  position: relative;
  padding: 5px 10px 5px 40px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #eeeeeeb0;
  }

  &::before {
    content: "";
    background-image: url(${({ active } : { active: boolean }) => active ? HeartActiveIcon : HeartIcon});
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 39px;
    margin: auto 0 auto -25px;
  }

`;

const StyledListing = styled.main`

  .container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .collection__button {
    width: 100%;
    max-width: 215px;
    max-height: 40px;
    border-radius: 100px;
    background-color: #4095DA;
    margin-left: 15px;
  }

  .collection__button:hover {
    background-color: #000;
    transition: 0.3s linear;
  }

  .collection__button-link {
    width: 100%;
  }

  .collection__button-text {
    padding: 10px 20px 10px 40px;
    text-align: center;
    color: #fff;
    position: relative;
  }

  .collection__button-text::before {
    content: "";
    background-image: url(./imgs/plus-icon.svg);
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto 0 15px 20px;
  }

  .hero {
    margin-top: 36px;
  }

  .hero__title__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hero__title-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5em;
    color: #02020B;
    margin: 0 30px 0 0;
  }

  .hero__title__title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 40px;
  }

  .hero__title__btn {
    border-radius: 100px;
    /* border: 2px solid #F5F5F5; */
    transition: 0.3s ease;
    margin-left: 40px;
  }

  .hero__title__btn-text {
    font-size: 14px;
    font-weight: 400;
    padding: 4px 10px;
  }

  .hero__title__btn-industrial {
    width: 100%;
    color: #757575;
  }

  .hero__title__buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  .share {
    position: relative;
    padding: 5px 10px 5px 40px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: #eeeeeeb0;
    }
  }

  .share::before {
    content: "";
    background-image: url(${ShareIcon});
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 34px;
    margin: auto 0 auto -20px;
  }

  .hero__subtitle {
    margin-top: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > * {
      white-space: nowrap;
    }
  }

  .hero__subtitle-location {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    margin-left: 16px;
  }

  .hero__subtitle-location::before {
    content: "";
    background-image: url(${MarkerIcon});
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: -2px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto 0 auto -20px;
  }

  .hero__subtitle-area {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    margin-left: 70px;
  }

  .hero__subtitle-area::before {
    content: "";
    background-image: url(${SquareIcon});
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: -3px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto 0 auto -20px;
  }

  .hero__host {
    display: flex;
    margin-top: 15px;
    align-items: center;

    a {
      display: flex;
      align-items: center;
    }

    img {
      width: 50px;
      height: 50px;
      margin: 0 20px;
      border-radius: 50%;
    }
  }

  .hero__host-grey {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
  }

  .hero__host-black {
    color: #000;
    font-size: 18px;
    font-weight: 500;
  }

  .hero__imgs {
    margin-top: 20px;

    &__img {
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 10px;
      padding: 25%;
    }

    .row {
      position: relative;
    }

    .column:first-child {
      width: 66.7%;
    }
    
    .column:last-child {
      flex-grow: 1;
      margin-left: 20px;
    }

    .hero__right-imgs {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .hero__imgs__img {
        flex-grow: 1;

        &:not(:first-child) {
          margin-top: 10px;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    @media screen and (max-width:768px) {
      .row {
        flex-direction: column;
      }

      .column {
        width: 100% !important;
      }
      
      .column:last-child {
        margin-top: 20px;
        margin-left: 0;
      }
    }

  }

  .column {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .hero__left-img {
    width: 100%;
    height: 100%;
  }

  .hero__right-bottom {
    margin-top: 20px;
  }

  .hero__liked {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
  }

  .hero__img__button {
    width: 100%;
    max-width: 200px;
    max-height: 50px;
    border-radius: 100px;
    background-color: #FFD548;
    margin-left: 15px;
    transition: background-color 0.3s linear;

    &.edit {
      .hero__img__btn-text {
        padding: 10px 20px;

        &::before {
          display: none;
        }
      }
    }

    &.delete {
      background-color: #fa6c62;

      .hero__img__btn-text {
        padding: 10px 20px;

        &::before {
          display: none;
        }
      }
    }

    &:hover {
      background-color: #e9bc29;
    }

    &.delete:hover {
      background-color: #ff4538;
    }

    .hero__img__btn-text {
      color: #000 !important;
    }
  }


  .hero__img__btn-text:hover {
    color: #fff;
  }

  .hero__img__btn {
    cursor: pointer;
    width: 100%;
  }

  .hero__img__btn-text {
    padding: 10px 20px 10px 40px;
    text-align: center;
    color: #000;
    font-weight: 500;
    font-size: 15px;
    position: relative;
  }

  .hero__img__btn-text::before {
    content: "";
    background-image: url(${DataIcon});
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto 0 15px 30px;
  }

  .hero::after {
    content: '';
    background-color: #F2F2F2;
    height: 2px;
    display: block;
  }

  .about__container {
    margin-top: 60px;
    /* max-width: 900px; */
    padding-bottom: 20px;

    .row {
      justify-content: space-between;
    }
  }

  .about__container .row {
    align-items: flex-start;
  }

  .about__title-title,
  .hours__title-title {
    font-size: 18px;
    font-weight: 500;
  }

  .hours__title-title {
    margin-bottom: 20px;
  }

  .hours__title {
    max-width: 500px;
    width: 100%;
    margin-right: 10px;
  }

  .hours__title-table {
    display: flex;
    justify-content: flex-start;
  }

  .about__title {
    max-width: 570px;
  }

  .work-day {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #757575;
    margin-right: 20px;
  }

  .hours-link {
    width: 100%;
    color: #4095DA;
  }

  .hours-link > div {
    margin: 22px 0;
  }

  .list__things {
    width: 100%;
    margin-top: 60px;
  }

  .list__things__container {
    width: 100%;
    max-width: 1140px;
  }

  .list__things-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.125em;
    margin-bottom: 20px;
  }

  .list__things__priority {
    margin-top: 30px;
  }

  .priority-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1em;
    color: #02020B;
    margin-bottom: 22px;

    &:not(:first-child) {
      margin-top: 25px;
    }
  }

  .list__things__priority .column {
    gap: 23px;
    align-items: flex-start;
    width: 33%;
  }

  .list__things__priority .row {
    align-items: flex-start;
  }

  .priority-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .priority-item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
    }

  }

  .priority-item-text {
    font-size: 16px;
    font-weight: 400;
    color: #757575;
    margin-left: 16px;
  }

  .antiseptics {
    margin-left: 23px;
  }

  .hemostatic-gauzes {
    margin-left: 15px;
  }

  .third,
  .second {
    margin-left: 70px;
  }

  .millitary-title {
    margin-top: 32px;
    margin-bottom: 22px;
  }

  .millitary-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .millitary-third,
  .millitary-second {
    margin-left: 70px;
  }

  .millitary-item-text {
    font-size: 16px;
    font-weight: 400;
    color: #757575;
    margin-left: 16px;
  }

  .humanitarian-title {
    margin-top: 32px;
    margin-bottom: 22px;
  }

  .humanitarian-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .humanitarian-item-text {
    font-size: 16px;
    font-weight: 400;
    color: #757575;
    margin-left: 16px;
  }

  .humanitarian__container .column {
    width: 33%;
  }

  .reviews {
    margin-top: 60px;
  }

  .reviews__container {
    max-width: 560px;
  }

  .reviews-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .rate-area>label:active {
    position: relative;
  }

  .rating-area {
    margin-right: 50px;
  }

  .reviews__area {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .avg-result {
    font-size: 16px;
    font-weight: 500;
  }

  .reviews__counter span {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
  }

  .reviews__comments {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .reviews__comments-comment {
    display: flex;
    justify-content: space-between;
    max-width: 560px;
    align-items: center;
  }

  .user__info {
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .reviews__comments-comment span {
    color: #757575;
    margin-left: 10px;
  }

  .username {
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
  }

  .posted-time {
    color: #757575;
    margin-left: auto;
  }

  .user-text {
    margin-top: 10px;
    color: #757575;
    max-width: 560px;
  }

  .reviews__comments__item {
    margin-top: 20px;
  }

  .pages__swiper i {
    border: solid #757575;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: 15px;
  }

  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-right: 15px;
  }

  .pages__swiper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 150px;
    margin: 30px auto;

    .arrow {
      cursor: pointer;
      &:hover i {
        border-color: #101010;
      }
    }
  }

  .pages__counter span {
    font-size: 16px;
    font-weight: 400;
  }

  .comment-title {
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0;
  }

  .comment-field {
    width: 100%;
    border: 1px #F2F2F2 solid;
  }

  .send-bttn {
    width: 100%;
    background-color: #EDF5FC;
    border: none;
    border-radius: 100px;
    color: #4095DA;
    margin-top: 20px;
    height: 50px;
    cursor: pointer;

    &:hover {
      background-color: #d9edff;
    }
  }

  .map__container {
    margin-bottom: 50px;
  }

  .map__container-title {
    margin-top: 60px;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
  }

  .message__to-singin {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #EDF5FC;
    border-radius: 10px;
  }

  .warning-icon {
    margin-left: 20px;
  }

  .message-text {
    margin-left: 20px;
  }

  .sing-in {
    color: #4095DA;
    text-decoration: underline;
  }

  .date__container {
    margin-top: 20px;
  }

  .date__container-title {
    font-size: 18px;
    font-weight: 500;
  }

  .date__container-text {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #757575;
    max-width: 660px;
  }

  .date__calendar {
    margin-top: 30px;
    width: 100%;
    position: relative;
  }

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .reviews__comments__item {
    .user-avatar img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
  }


  @media screen and (min-width: 769px) and (max-width:1024px) {
    .container {
      max-width: 760px;
    }

    .hero__title-title {
      font-size: 18px;
    }

    .reviews__container {
      width: 100%;
      margin: auto;
    }

    .hero__right-bottom {
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 481px) and (max-width:768px) {
    .header__buttons {
      max-width: 300px;
    }

    .collection__button {
      width: 60%;
    }

    .collection__button-text {
      font-size: 12px;
    }

    .collection__button-text::before {
      margin: auto 0 12px 20px;
    }

    .container {
      max-width: 760px;
    }

    .hero__title__container {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 20px;
    }

    .hero__title-title {
      font-size: 18px;
      margin-right: 0;
    }

    .hero__title__buttons {
      margin-left: 25px;
    }

    .reviews__container {
      width: 100%;
      margin: auto;
    }

    .hero__right-bottom {
      margin-top: 10px;
    }

    .hero__liked {
      justify-content: center;
    }

    .second,
    .third {
      margin-left: 0px;
    }

    .millitary-second,
    .millitary-third {
      margin-left: 0px;
    }

    .priority-item-text {
      font-size: 12px;
    }

    .millitary-item-text {
      font-size: 12px;
    }

    .humanitarian-item-text {
      font-size: 12px;
    }

    .hours__title {
      display: none;
    }

  }

  @media screen and (min-width: 320px) and (max-width:480px) {

    .header__container {
      width: 100%;
    }

    .header__buttons {
      max-width: 140px;
    }

    .collection__button {
      display: none;
    }

    .collection__button-text {
      font-size: 12px;
    }

    .collection__button-text::before {
      margin: auto 0 12px 20px;
    }

    .container {
      max-width: 500px;
    }

    .sing-in__button {
      display: none;
    }

    .sing-in-icon {
      display: block;
      fill: #4095DA;
      width: 20px;
      height: 20px;
    }

    .hero__title__container {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 20px;
    }

    .hero__title-title {
      font-size: 18px;
      margin-right: 0;
    }

    .hero__title__buttons {
      margin-left: 25px;
    }

    .hero__subtitle {
      flex-direction: column;
    }

    .hero__subtitle-area {
      margin-left: 16px;
    }

    .reviews__container {
      width: 100%;
      margin: auto;
    }

    .row {
      flex-direction: column;
    }

    .list__things__priority .column {
      width: 100%;
      gap: 40px;
    }

    .hero__right-bottom {
      margin-top: 10px;
    }

    .hero__right-imgs {
      margin-top: 10px;
      height: 100%;
      width: 100%;
    }

    .hero__liked {
      justify-content: center;
    }

    .reviews__area {
      max-width: 300px;
    }

    .avg-result {
      font-size: 14px;
    }

    .second,
    .third {
      margin-left: 0px;
      margin-top: 40px;
    }

    .millitary-second,
    .millitary-third {
      margin-left: 0px;
      margin-top: 40px;
    }

    .priority-item-text {
      font-size: 12px;
    }

    .millitary-item-text {
      font-size: 12px;
    }

    .humanitarian-item-text {
      font-size: 12px;
    }

    .hours__title {
      display: none;
    }

    .posted-time {
      font-size: 14px;
    }

    .username {
      font-size: 15px;
    }

  }

  @media screen and (max-width:320px) {

    .header__container {
      width: 100%;
    }

    .header__buttons {
      max-width: 140px;
    }

    .collection__button {
      display: none;
    }

    .collection__button-text {
      font-size: 12px;
    }

    .collection__button-text::before {
      margin: auto 0 12px 20px;
    }

    .container {
      max-width: 500px;
    }

    .sing-in__button {
      display: none;
    }

    .sing-in-icon {
      display: block;
      fill: #4095DA;
      width: 20px;
      height: 20px;
    }

    .hero__title__container {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 20px;
    }

    .hero__title-title {
      font-size: 18px;
      margin-right: 0;
    }

    .hero__title__buttons {
      margin-left: 25px;
    }

    .hero__subtitle {
      flex-direction: column;
    }

    .hero__subtitle-area {
      margin-left: 16px;
    }

    .reviews__container {
      width: 100%;
      margin: auto;
    }

    .row {
      flex-direction: column;
    }

    .list__things__priority .column {
      width: 100%;
      gap: 40px;
    }

    .hero__right-bottom {
      margin-top: 10px;
    }

    .hero__right-imgs {
      margin-top: 10px;
    }

    .hero__liked {
      justify-content: center;
    }

    .reviews__area {
      max-width: 300px;
    }

    .avg-result {
      font-size: 14px;
    }

    .second,
    .third {
      margin-left: 0px;
      margin-top: 40px;
    }

    .millitary-second,
    .millitary-third {
      margin-left: 0px;
      margin-top: 40px;
    }

    .priority-item-text {
      font-size: 12px;
    }

    .millitary-item-text {
      font-size: 12px;
    }

    .humanitarian-item-text {
      font-size: 12px;
    }

    .hours__title {
      display: none;
    }

    .posted-time {
      font-size: 14px;
    }

    .username {
      font-size: 15px;
    }

  }

`;
