import { gql } from "apollo-boost";

export const ADD_COMMENT = gql`
  mutation AddComment($input: AddCommentInput!) {
    addComment(input: $input) {
      id
      date
      comment
    }
  }
`;
