import { gql } from "apollo-boost";

export const PROVIDER = gql`
  query Provider($id: Int!, $commentsPage: Int!, $limit: Int!) {
    provider(id: $id) {
      id
      title
      description
      image
      type
      address
      country
      city
      capacity
      latitude
      longitude
      user {
        id
        name
        avatar
        hasWallet
      }
      bookings {
        total
        result {
          id
          user {
            id
            name
            avatar
          }
          date
        }
      }
      working_hours {
        mon { from to }
        tue { from to }
        wed { from to }
        thu { from to }
        fri { from to }
        sat { from to }
        sun { from to }
      }
      rating {
        current
        average
        count
      }
      comments (page: $commentsPage, limit: $limit) {
        total
        result {
          id
          comment
          date
          user {
            name
            avatar
            id
          }
        }
      }
      aids {
        id
        provider {
          id
          title
          image
        }
        details {
          id
          parentId
          name
          parentName
        }
      }
      currentUser {
        isFavorite
        bookings {
          id
          date
        }
      }
    }
  }
`;
