import { gql } from "apollo-boost";

export const LOG_OUT = gql`
  mutation LogOut {
    logOut {
      id
      name
      avatar
      email
      token
      hasWallet
      didRequest
    }
  }
`;
