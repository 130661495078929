import { gql } from "apollo-boost";

export const PROVIDER_MOST_VISITED = gql`
  query ProviderMostVisited($page: Int!, $limit: Int!) {
    providerMostVisited (page: $page, limit: $limit) {
      total
      provider {
        id
        title
        image
        address
        capacity
        user {
          avatar
        }
      }
    }
  }
`;
