import React from "react";
import { Link } from "react-router-dom";
import { Card, Typography } from "antd";
import { formatListingPrice } from "../../utils";
import { Providers_providers_result } from "lib/graphql/queries/Providers/__generated__/Providers";

interface Props {
  listing: Providers_providers_result;
}

const { Text, Title } = Typography;

export const ListingCard = ({ listing }: Props) => {
  const { id, title, image, address, capacity } = listing;

  return (
    <Link to={`/listing/${id}`}>
      <Card
        hoverable
        cover={
          <div
            style={{ backgroundImage: `url(${image[0]})` }}
            className="listing-card__cover-img"
          />
        }
      >
        <div className="listing-card__details">
          <div className="listing-card__description">
            <Title level={4} className="listing-card__price">
              {formatListingPrice(capacity)}
              <span>
                {" "}
                m<sup>2</sup>
              </span>
            </Title>
            <Text strong ellipsis className="listing-card__title">
              {title}
            </Text>
            <Text ellipsis className="listing-card__address">
              {address}
            </Text>
          </div>

          {/* <div className="listing-card__dimensions listing-card__dimensions--guests">
            <Icon type="home" style={{ color: iconColor }} />
          </div> */}
        </div>
      </Card>
    </Link>
  );
};
