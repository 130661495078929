import MedicalKitsIcon from "sections/Home/imgs/icon.medical-kits.svg";
import TourniquetsIcon from "sections/Home/imgs/icon.tourniquets.svg";
import AntisepticsIcon from "sections/Home/imgs/icon.antiseptics.svg";
import HemostaticGauzesIcon from "sections/Home/imgs/icon.hemostatic-gauzes.svg";
import GlovesIconIcon from "sections/Home/imgs/icon.gloves.svg";
import DisinfectorIcon from "sections/Home/imgs/icon.disinfector.svg";
import ScissorsIcon from "sections/Home/imgs/icon.scissors.svg";
import PlasterIcon from "sections/Home/imgs/icon.plaster.svg";
import Marker2Icon from "sections/Home/imgs/icon.marker (1).svg";
import BandageIcon from "sections/Home/imgs/icon.bandage.svg";
import ScrimsIcon from "sections/Home/imgs/icon.scrims.svg";
import ShoesIcon from "sections/Home/imgs/icon.shoes.svg";
import NightVisionOpticsIcon from "sections/Home/imgs/icon.night vision optics.svg";
import ThermographicCameraIcon from "sections/Home/imgs/icon.thermographic camera.svg";
import MilitaryBackpacksIcon from "sections/Home/imgs/icon.military backpacks.svg";
import TacticalFirstAidKitsIcon from "sections/Home/imgs/icon.tactical first aid kits.svg";
import MilitarySocksIcon from "sections/Home/imgs/icon.military socks.svg";
import MilitaryGlovesIcon from "sections/Home/imgs/icon.military gloves.svg";
import KneePadsIcon from "sections/Home/imgs/icon.knee pads.svg";
import ThermalUnderwearIcon from "sections/Home/imgs/icon.thermal underwear.svg";
import DiapersIcon from "sections/Home/imgs/icon.diapers.svg";
import WetWipesIcon from "sections/Home/imgs/icon.wet wipes.svg";
import CampingMatsIcon from "sections/Home/imgs/icon.camping mats.svg";
import SleepingBagsIcon from "sections/Home/imgs/icon.sleeping bags.svg";
import PowerbanksIcon from "sections/Home/imgs/icon.powerbanks.svg";
import ChargingCordsIcon from "sections/Home/imgs/icon.charging cords.svg";
import ElectricityGeneratorsIcon from "sections/Home/imgs/icon.electricity generators.svg";
import FlashlightsIcon from "sections/Home/imgs/icon.flashlights.svg";
import WaterFiltersIcon from "sections/Home/imgs/icon.water filters.svg";
import PetFoodIcon from "sections/Home/imgs/icon.pet food.svg";
import NonPerishableIcon from "sections/Home/imgs/icon.non-perishable.svg";

export const AID_ICONS = {
  2: MedicalKitsIcon,
  3: TourniquetsIcon,
  4: AntisepticsIcon,
  5: HemostaticGauzesIcon,
  6: BandageIcon,
  7: GlovesIconIcon,
  8: DisinfectorIcon,
  9: ScissorsIcon,
  10: PlasterIcon,
  11: Marker2Icon,
  12: BandageIcon,
  14: ScrimsIcon,
  15: ShoesIcon,
  16: NightVisionOpticsIcon,
  17: ThermographicCameraIcon,
  18: MilitaryBackpacksIcon,
  19: TacticalFirstAidKitsIcon,
  20: MilitarySocksIcon,
  21: MilitaryGlovesIcon,
  22: KneePadsIcon,
  23: ThermalUnderwearIcon,
  25: DiapersIcon,
  26: WetWipesIcon,
  27: CampingMatsIcon,
  28: SleepingBagsIcon,
  29: PowerbanksIcon,
  30: ChargingCordsIcon,
  31: ElectricityGeneratorsIcon,
  32: FlashlightsIcon,
  33: WaterFiltersIcon,
  34: PetFoodIcon,
  35: NonPerishableIcon,
}
