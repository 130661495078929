import { message, notification } from "antd";

export const iconColor = "#1890ff";

export const formatListingPrice = (price: number, round = true) => {
  const formattedListingPrice = round ? Math.round(price / 100) : price / 100;
  return `${formattedListingPrice}`;
};

export const displaySuccessNotification = (
  message: string,
  description?: string
) => {
  return notification["success"]({
    message,
    description,
    placement: "topLeft",
    style: {
      marginTop: 50,
    },
  });
};

export const displayErrorMessage = (error: string) => {
  return message.error(error);
};

export const beforeImageUpload = (file: File): boolean => {
  const fileIsValidImage =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/webp";
  const fileIsValidSize = file.size / 1024 / 1024 < 5;

  if (!fileIsValidImage) {
    displayErrorMessage("You're only able to upload valid JPG or PNG files!");
  }

  if (!fileIsValidSize) {
    displayErrorMessage(
      "You're only able to upload valid image files of under 5MB in size!"
    );
  }

  return fileIsValidImage && fileIsValidSize;
};

export const getBase64Value = (
  file: File | Blob,
  callback: (imageBase64Value: string) => void
): void => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    if (typeof reader.result === "string") callback(reader.result);
  };
};
