import { gql } from "apollo-boost";

export const PROVIDER_COUNT = gql`
  query ProviderCount($page: Int!, $limit: Int!) {
    providerCount (page: $page, limit: $limit) {
      city
      cnt_id
    }
  }
`;
