import React from "react";
import styled from "styled-components";
import { Empty } from "antd";
import ProviderCard from "lib/components/ProviderCard";
import CardGrid from "lib/components/CardGrid";
import { User_user } from "lib/graphql/queries/User/__generated__/User";
import { useMutation } from "@apollo/react-hooks";
import { displayErrorMessage } from "lib/utils";
import { DeleteFavorite, DeleteFavoriteVariables } from "lib/graphql/mutations/DeleteFavorite/__generated__/DeleteFavorite";
import { DELETE_FAVORITE } from "lib/graphql/mutations";

interface Props {
  user: User_user;
  viewerIsUser: boolean,
  onFavoriteDelete: () => void;
}

export const UserFavorites = ({
  viewerIsUser,
  user,
  onFavoriteDelete,
}: Props) => {

  const [deleteFavorite] = useMutation<DeleteFavorite, DeleteFavoriteVariables>(DELETE_FAVORITE, {
    onError: () => displayErrorMessage("Some error occurred while changing favorite status!"),
    onCompleted: () => onFavoriteDelete(),
  });
  
  return user.favorites && (
    <StyledContainer>
      <h3 className="savings__container-title">Favorites listings</h3>
      {viewerIsUser ? (
        <p className="savings__container-subtitle">This section displays ads that you have added to your saved.</p>
      ) : (
        <p className="savings__container-subtitle">This section displays listings that {user.name} has added to favorites.</p>
      )}
      <StyledFavorites>
        <div className="collection__cards__container">
          {user.favorites.result.length ? (
            <CardGrid>
              {user.favorites.result.map(favorite => (
                <ProviderCard   
                  key={favorite.provider.id}
                  id={favorite.provider.id}
                  address={favorite.provider.address}
                  capacity={favorite.provider.capacity}
                  count={(favorite.provider.bookings && favorite.provider.bookings.total) || 0}
                  title={favorite.provider.title}
                  locationImage={favorite.provider.image[0]}
                  avatarImage={favorite.provider.user.avatar}
                  isFavorite
                  onFavoriteClick={() => deleteFavorite({ variables: { input: { id: favorite.provider.id } } })}
                />
              ))}
            </CardGrid>
          ) : (
            <div className="booked__items">
              <Empty description="You don't have any saved listings!" />
            </div>
          )}
        </div>
      </StyledFavorites>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  margin-top: 80px;

  .savings__container-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .savings__container-subtitle {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }
`;

const StyledFavorites = styled.div`

  .collection__cards__container {
    margin: 0;
  }

`;
