import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";
import { LOG_OUT } from "lib/graphql/mutations";
import { LogOut as LogOutData } from "lib/graphql/mutations/LogOut/__generated__/LogOut";
import { displaySuccessNotification, displayErrorMessage } from "lib/utils";
import { Viewer } from "lib/types";
import SignInIcon from "sections/Home/imgs/bttn.sign in.svg";

interface Props {
  viewer: Viewer;
  setViewer: (viewer: Viewer) => void;
}

export const Login: React.FC<Props> = ({ viewer, setViewer }) => {
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [logOut] = useMutation<LogOutData>(LOG_OUT, {
    onCompleted: (data) => {
      if (data && data.logOut) {
        setViewer(data.logOut);
        history.push("/");
        sessionStorage.removeItem("token");
        displaySuccessNotification("You've successfully logged out!");
      }
    },
    onError: () => {
      displayErrorMessage(
        "Sorry! We weren't able to log you out. Please try again later!"
      );
    },
  });

  const handleLogOut = () => {
    logOut();
  };

  return (
    viewer.id && viewer.avatar ? (
      <StyledProfileMenu>
        <div className={`backdrop ${active ? 'active' : ''}`} onClick={() => setActive(false)}></div>
        <p className="nickname">{viewer.name && viewer.name.split(' ')[0]}</p>
        <Link to={`/user/${viewer.id}`} className="avatar"><img src={viewer.avatar} alt="" /></Link>
        <i className={`arrow-down ${active ? 'active' : ''}`} onClick={() => setActive(prev => !prev)}></i>
        <div className={`profile__window ${active ? 'active' : ''}`}>
          <div className="inner">
            <img src={viewer.avatar} alt="" className="avatar-window" />
            <h4 className="nickname-profile">{viewer.name}</h4>
            <p className="contact">Contact: {viewer.email}</p>
            {/* <h4 className="details">Additional Details</h4>
            <p>Interested in major help?</p>
            <p> Contact the DropForUkraine team!</p>
            <a href="#1" className="profile-btn">Talk to us</a> */}
            <div className="logout" onClick={handleLogOut}>Log out</div>
            <p className="description">
              <a
                href="https://stripe.com/en-US/connect"
                target="_blank"
                rel="noopener noreferrer"
              >
                DropForUkraine
              </a> is a nonprofit organization
            </p>
          </div>
      </div>
      </StyledProfileMenu>
    ) : (
      <StyledLogin>
        <img src={SignInIcon} alt="1" />
        <Link to="/login" className="sing-in__button-link">
          {" "}
          Sign in
        </Link>
      </StyledLogin>
    )
  );
};

const StyledLogin = styled.div`
  color: #4095DA;
  display: inline-flex;

  .sing-in__button-link {
    transition: 0.5s ease;
    width: 50px;
  }

  img {
    margin-right: 10px;
  }

  .sing-in__button-link:hover {
    color: #000;
    text-decoration: underline;
  }

  .sing-in__button-text { 
    padding-left: 15px;
    padding-top: 15px;
    font-size: 15px;
    font-weight: 400;
    position: relative;
  }
`;

const StyledProfileMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nickname {
    margin: 0 15px 0 0;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
  }

  .avatar {
    width: 40px;
    height: 40px;
    z-index: 1;
    cursor: pointer;
  }

  .arrow-down {
    cursor: pointer;
    transform: rotate(45deg);
    transition: 0.4s ease;
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    z-index: 1;
    margin-left: 15px;
  }

  .arrow-down.active {
    transform: rotate(-135deg);
  }

  .profile__window {
    position: absolute;
    width: 100%;
    max-width: 367px;
    top: -800px;
    opacity: 0;
    z-index: 10;
    right: 1.4%;
    transition: 0.4s linear;
    background-color: #fff;
    box-shadow: 0px 16px 16px rgba(50, 50, 71, 0.08), 0px 24px 32px rgba(50, 50, 71, 0.08);
    border-radius: 10px;

    @media screen and (max-width: 600px) {
      position: fixed;
      left: 50%;
      right: unset;
      transform: translate(-50%, -50%);
    }

    &.active {
      top: 100%;
      opacity: 100%;

      @media screen and (max-width: 600px) {
        top: 50%;
      }
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  }

  .backdrop {
    display: none;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: transparent;


    @media screen and (max-width: 600px) {
      background-color: rgba(0, 0, 0, 0.3);
    }

    &.active {
      position: fixed;
      display: block;
    }
  }

  .avatar-window {
    width: 150px;
    height: 150px;
    margin-top: 20px;
  }

  .nickname-profile {
    margin: 20px 0 30px 0;
  }

  .contact {
    margin-bottom: 30px;
  }

  .profile-btn {
    margin: 20px 0 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    gap: 10px;
    background: #EDF5FC;
    border-radius: 100px;
    color: #4095DA;
    font-size: 15px;
    font-weight: 500;
  }

  .details {
    margin-bottom: 20px;
  }

  .logout {
    margin-bottom: 30px;
    color: #4095DA;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .description {
    padding-bottom: 32px;
    font-size: 12px;
    font-weight: 400;

    a:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }

  h4 {
    font-weight: bold;
  }

`;
