import { gql } from "apollo-boost";

export const SET_PROVIDER_RATING = gql`
  mutation SetProviderRating($input: SetProviderRatingInput!) {
    setProviderRating(input: $input) {
      id
      current
      average
      count
    }
  }
`;
