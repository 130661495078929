import React from "react";
import styled from "styled-components";
import { Empty } from "antd";
import { useParams } from "react-router-dom";
import { User_user, User_user_providers } from "lib/graphql/queries/User/__generated__/User";
import { Viewer } from "lib/types";
import ProviderCard from "lib/components/ProviderCard";
import CardGrid from "lib/components/CardGrid";

interface Props {
  user: User_user;
  userListings: User_user_providers;
  viewer: Viewer;
}

interface MatchParams {
  id: string;
}

export const UserListings: React.FC<Props> = ({ user, userListings, viewer }) => {
  const { id } = useParams<MatchParams>();
  const viewerIsUser = viewer.id === +id;
  return (
    <StyledContainer>
      {viewerIsUser ? (
      <>
        <h1 className="hero__container-title">My profile</h1>
        <h4 className="hero__container-subtitle">My listings</h4>
        <p className="hero__container-description">This section displays the listings that you have placed and made available for booking.</p>
      </>
      ): (
        <>
          <h1 className="hero__container-title">{user.name}'s profile page</h1>
          <h4 className="hero__container-subtitle">Listings</h4>
          <p className="hero__container-description">This section displays the listings that {user.name} have placed and made available for visiting.</p>
        </>
      )}
      <StyledListings>
        <div className="collection__cards__container">
          {userListings.result.length > 0 ? (
            <CardGrid>
              {userListings.result.map(({id, address, image, capacity, title, bookings }) => (
                <ProviderCard   
                  key={id}
                  id={id}
                  address={address}
                  capacity={capacity}
                  count={(bookings && bookings.total) || 0}
                  title={title}
                  locationImage={image[0]}
                  avatarImage={user.avatar}
                />
              ))}
            </CardGrid>
          ) : (
            <div className="booked__items">
              <Empty description={`${viewerIsUser ? "You don't" : user.name + " doesn't"} have any listings!`} />
            </div>
          )}
        </div>
      </StyledListings>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  .hero__container-title {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .hero__container-subtitle {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px;
  }

  .hero__container-description {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: #757575;
    margin-bottom: 20px;
  }
`;

const StyledListings = styled.div`

  .collection__cards__container {
    margin: 0;
  }

`;
