import React from 'react';
import styled from 'styled-components';
import LogoIcon from './img/logo.png';
import UpworkIcon from './img/upwork.svg';
import MailIcon from './img/mail.svg';

export const Contacts: React.FC = () => {
  return (
    <StyledContacts>
      <a className="logo" href="https://mappitall.com/" target="_blank" rel="noreferrer">
        <img src={LogoIcon} alt="logo" width="62" height="62" />
      </a>
      <div className="right">
				<div className="title">
					<div>Like the map?</div>
					<a href="https://mappitall.com/" target="_blank" rel="noreferrer">Get in touch</a>
				</div>
        <div className="icons">
          <a href="https://www.upwork.com/freelancers/~01322a1d5306e7003e" target="_blank" rel="noreferrer">
            <img src={UpworkIcon} alt="upwork-logo" width="25" height="25" />
          </a>
          <a href="mailto:contact@mappitall.com">
            <img src={MailIcon} alt="mail-logo" width="25" height="25" />
          </a>
        </div>
      </div>
    </StyledContacts>
  );
}

const StyledContacts = styled.div`
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  right: 30px;
  bottom: 30px;  
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 20px rgba(114, 119, 143, 0.2);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  z-index: 1000;

  @media screen and (max-width: 720px) {
    right: 20px;
    bottom: 20px;  
  }

  @media screen and (max-width: 540px) {
    right: 50%;
    transform: translateX(50%);
  }

  .logo {
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  
  .title {
    &, a, a:active, a:visited {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01em;
      color: #101010;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .icons {
    margin-top: 5px;

    &, a {
      display: flex;
      align-items: center;
    }

    a {
      justify-content: center;
    }

    a:not(:last-child) {
      margin-right: 10px;
    }
  }

`;

export default Contacts;
