import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { OSContext } from 'index';

export const Page: React.FC = ({ children }) => {
  const location = useLocation();
  const osRef = useContext(OSContext);

  useEffect(() => {
    if (osRef && osRef.current) {
      const instance = osRef.current.osInstance();
      if (instance) instance.scroll({ top: 0 });
    }
  }, [osRef, location]);

  return (<>{children}</>)
};

export default Page;
