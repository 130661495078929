import { useLayoutEffect, useContext } from "react";
import { OSContext } from 'index';

export const useScrollToTop = () => {
  const osRef = useContext(OSContext);
  useLayoutEffect(() => {
    if (osRef && osRef.current) {
      const instance = osRef.current.osInstance();
      if (instance) instance.scroll({ top: 0 });
    }
  }, [osRef]);
};
