import React, { useState, useEffect, useRef, createContext, MutableRefObject } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider, useMutation } from "@apollo/react-hooks";
// import { StripeProvider, Elements } from "react-stripe-elements";
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Affix, Spin, Layout } from "antd";
import {
  AppHeader,
  Home,
  Host,
  Listing,
  Listings,
  Login,
  NotFound,
  // Stripe,
  User,
  Edit,
} from "./sections";
import { AppHeaderSkeleton, ErrorBanner, Footer } from "./lib/components";
import { LOG_IN } from "./lib/graphql/mutations";
import {
  LogIn as LogInData,
  LogInVariables,
} from "./lib/graphql/mutations/LogIn/__generated__/LogIn";
import { Viewer } from "./lib/types";
import Page from "lib/components/Page";
import { Contacts } from "lib/components";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.scss";

const client = new ApolloClient({
  uri: "/api",
  request: async (operation: {
    setContext: (arg0: { headers: { "X-CSRF-TOKEN": string } }) => void;
  }) => {
    const token = sessionStorage.getItem("token");
    operation.setContext({
      headers: {
        "X-CSRF-TOKEN": token || "",
      },
    });
  },
});

export const OSContext = createContext<MutableRefObject<OverlayScrollbarsComponent | null> | null>(null);

const initialViewer: Viewer = {
  id: null,
  token: null,
  avatar: null,
  name: null,
  email: null,
  hasWallet: null,
  didRequest: false,
};

const App = () => {
  const [viewer, setViewer] = useState<Viewer>(initialViewer);
  const [logIn, { error }] = useMutation<LogInData, LogInVariables>(LOG_IN, {
    onCompleted: (data) => {
      if (data && data.logIn) {
        setViewer(data.logIn);

        if (data.logIn.token) {
          sessionStorage.setItem("token", data.logIn.token);
        } else {
          sessionStorage.removeItem("token");
        }
      }
    },
  });
  const logInRef = useRef(logIn);
  const osRef = useRef<OverlayScrollbarsComponent | null>(null);

  useEffect(() => {
    logInRef.current();
  }, []);

  if (!viewer.didRequest && !error) {
    return (
      <Layout className="app-skeleton">
        <AppHeaderSkeleton />
        <div className="app-skeleton__spin-section">
          <Spin size="large" tip="Launching DropForUkraine" />
        </div>
      </Layout>
    );
  }

  const logInErrorBannerElement = error ? (
    <ErrorBanner description="We weren't able to verify if you were logged in. Please try again later!" />
  ) : null;

  return (
    <>
      <Contacts />
      <OverlayScrollbarsComponent
        ref={osRef}
        options={{ scrollbars: { autoHide: 'scroll' } }}
        className="body-scrollbars"
      >
        {/* <StripeProvider apiKey={process.env.REACT_APP_S_PUBLISHABLE_KEY as string}> */}
          <OSContext.Provider value={osRef}>
            <Router>
              <Page>
                <Layout id="app">
                  {logInErrorBannerElement}
                  <Affix offsetTop={0} className="app__affix-header">
                    <div><AppHeader viewer={viewer} setViewer={setViewer} /></div>
                  </Affix>   
                  <Switch>
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route exact path="/host">
                      <Host viewer={viewer} />
                    </Route>
                    <Route exact path="/listing/:id">
                      {/* <Elements> */}
                        <Listing viewer={viewer} />
                      {/* </Elements> */}
                    </Route>
                    <Route exact path="/edit/:id">
                      <Edit viewer={viewer} />
                    </Route>
                    <Route exact path="/listings/:location?">
                      <Listings />
                    </Route>
                    <Route exact path="/login">
                      <Login setViewer={setViewer} />
                    </Route>
                    {/* <Route exact path="/stripe">
                      <Stripe viewer={viewer} setViewer={setViewer} />
                    </Route> */}
                    <Route exact path="/user/:id">
                      <User viewer={viewer} />
                    </Route>
                    <Route>
                      <NotFound />
                    </Route>
                  </Switch>
                  <Footer />
                </Layout>
              </Page>
            </Router>
          </OSContext.Provider>
        {/* </StripeProvider> */}
      </OverlayScrollbarsComponent>
    </>
  );
};

render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
