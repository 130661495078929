import React from "react";
import { Select } from "antd";
import { ProvidersFilter } from "../../../../lib/graphql/globalTypes";

interface Props {
  filter: ProvidersFilter;
  setFilter: (filter: ProvidersFilter) => void;
}

const { Option } = Select;

export const ListingsFilters = ({ filter, setFilter }: Props) => {
  return (
    <div className="sort">
      <span>Sort by size</span>
      <Select
        className="sort-list"
        value={filter}
        onChange={(filter: ProvidersFilter) => setFilter(filter)}
      >
        <Option value={ProvidersFilter.PRICE_LOW_TO_HIGH}>
          From small to large
        </Option>
        <Option value={ProvidersFilter.PRICE_HIGH_TO_LOW}>
          From large to small
        </Option>
      </Select>
    </div>
  );
};
