import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Empty } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import moment from "moment";
import { User_user_bookings } from "lib/graphql/queries/User/__generated__/User";
import MarkerIcon from "sections/Home/imgs/icon.marker.svg";
import CalendarIcon from "sections/Home/imgs/icon.calendar.svg";
import { DeleteBooking, DeleteBookingVariables } from "lib/graphql/mutations/DeleteBooking/__generated__/DeleteBooking";
import { DELETE_BOOKING } from "lib/graphql/mutations";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { displaySuccessNotification, displayErrorMessage } from "lib/utils";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface Props {
  userBookings: User_user_bookings;
  handleRefetch: () => void;
}

export const UserBookings: React.FC<Props> = ({ userBookings, handleRefetch }) => {
  const [loading, setLoading] = useState<Record<number, boolean>>({});
  const [deleteBooking, { loading: deleteLoading, called }] = useMutation<DeleteBooking, DeleteBookingVariables>(DELETE_BOOKING, {
    onError: () => displayErrorMessage("Some error occurred while deleting the booking!"),
    onCompleted: () => {
      displaySuccessNotification("You've successfully deleted your booking!");
      handleRefetch();
    },
  });

  useEffect(() => {
    if (called && !deleteLoading) setLoading({});
  }, [deleteLoading, called]);

  return (
    <StyledBookings>
      <h3 className="bookings-title">
        My bookings
      </h3>
      <p className="bookings-subtitle">This section highlights the bookings you've made at various collection points.</p>
      {userBookings.result && userBookings.result.length ? (
        <div className="bookings__content__wrapper">
          <table className="resp-tab">
            <thead>
              <tr>
                <th>Collection point</th>
                <th>Type</th>
                <th>Date</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {userBookings.result.map(booking => (
                <tr key={booking.id}>
                  <td>
                    <div className="table-item">
                      <img src={booking.provider.image[0]} alt="location" />
                      <Link className="table-link" to={`/listing/${booking.provider.id}`}>
                        {booking.provider.title}
                      </Link>
                    </div>
                  </td>
                  <td>{booking.provider.type}</td>
                  <td>
                    <p className="table-date">{moment(booking.date).format("ddd: YYYY-MM-DD")}</p>
                  </td>
                  <td>
                    <p className="table-location">{booking.provider.address}</p>
                  </td>
                  <td>
                    <button
                      className="table-bttn"
                      onClick={() => {
                        deleteBooking({ variables: { input: { id: booking.id } } });
                        setLoading({ [booking.id]: true });
                      }}
                      disabled={deleteLoading}
                    >
                      {loading[booking.id] ? <FontAwesomeIcon icon={faSpinner} spin /> : "Cancel booking"}
                    </button>
                  </td>
                </tr>
              )) }
            </tbody>
          </table>
        </div>
      ): (
        <div className="booked__items">
          <Empty description="You haven't made any bookings!" />
        </div>
      )}
    </StyledBookings>
  );
};

const StyledBookings = styled.div`

  width: 100%;
  max-width: 1180px;
  margin-top: 80px;

  .bookings-title {
    font-weight: 500;
    font-size: 18px;
  }

  .bookings-subtitle {
    font-weight: 400;
    font-size: 16px;
    color: #757575;
    margin-top: 15px;
  }

  .bookings__content__wrapper {
    margin-top: 50px;
    max-width: 1180px;
  }

  table {
    width: 100%;
    color: #02020B;
    p {
      margin: 0;
    }
  }

  td,
  th {
    text-align: left;
    padding: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #757575;
  }

  .table-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #02020B;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  .table-link {
    transition: 0.3s linear;
  }

  .table-link:hover {
    color: #4095DA;
  }

  .table-bttn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    gap: 10px;
    width: 147px;
    height: 35px;
    background: #EDF5FC;
    border-radius: 100px;
    color: #4095DA;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #d6e9f9;
    }
  }

  .table-location {
    position: relative;
    margin-left: 26px;
  }

  .table-location::before {
    content: "";
    background-image: url(${MarkerIcon});
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: -66px;
    right: 0;
    margin: auto 0 auto 40px;
    bottom: 0;
  }

  .table-date {
    position: relative;
    margin-left: 26px;
    white-space: nowrap;
  }

  .table-date::before {
    content: "";
    background-image: url(${CalendarIcon});
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: -66px;
    right: 0;
    margin: auto 0 auto 40px;
    bottom: 0;
  }

  .resp-tab {
    width: 100%;
    color: #02020B;

    tbody tr:hover {
      background-color: #edf5fc70;
    }
  }

  .resp-tab th,
  .resp-tab td {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #757575;
  }

  .resp-tab th {
    color: #757575;
    text-align: center;
  }

  .resp-tab tr:nth-child(even) {
    align-items: center;
  }

  @media(max-width: 768px) {
    .resp-tab thead {
      display: none;
    }
    .resp-tab tr {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
    .resp-tab td {
      margin: 0 -1px -1px 0;
      padding-top: 35px;
      position: relative;
      width: 50%;
    }
    .resp-tab td span {
      display: block;
    }
  }

  @media(max-width: 480px) {
    .resp-tab td {
      width: 100%;
    }
    .table-date {
      margin-left: 0;
    }
  }
`;
