import React, { useState, useEffect }  from "react";
import { Link, useLocation } from "react-router-dom";
import { Viewer } from "../../lib/types";
import { Login } from "./components";
import logo from "./imgs/header-logo.svg";
// import divider from "./imgs/divider vertical.svg";
import vertical_line from "./imgs/vertical line.svg";
import mappitall_logo from "./imgs/logo mappitall.svg";

interface Props {
  viewer: Viewer;
  setViewer: (viewer: Viewer) => void;
}

export const AppHeader = ({ viewer, setViewer }: Props) => {
  const location = useLocation();
  const [headerClass, setHeaderClass] = useState<'header' | 'secondary'>('header');

  useEffect(() => {
    setHeaderClass(location.pathname === '/' ? 'header' : 'secondary');
  }, [location]);

  return (
    <header className={headerClass}>
        <div className="top__bttn">
          <a href="#1" className="donation-link">
            <p className="top__bttn-text">
              Support Ukraine - donate to buy a Bayraktar dron
            </p>
          </a>
        </div>
        <div className="menu-bar">
          <div className="container">
            <div className="header__container">
              <div className="collection__logo">
                <div className="header-logo">
                  <Link to={`/`}>
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <div className="vertical">
                  <img src={vertical_line} alt="" />
                </div>

                <div className="mappitall-logo">
                  <a href="https://mappitall.com/">
                    <img src={mappitall_logo} alt="" />
                  </a>
                </div>
              </div>
              <div className="header__buttons">
                {/* <div className="language__button">
                  <a href="#1" className="language">
                    EN
                  </a>
                </div>
                <div className="divider">
                  {" "}
                  <img src={divider} alt="" />
                </div> */}
                <div className="collection__button">
                  <Link to="/host" className="collection__button-link">
                    <p className="collection__button-text">
                      {" "}
                      Add collection point
                    </p>
                  </Link>
                </div>
                <Login viewer={viewer} setViewer={setViewer} />
              </div>
            </div>
          </div>
        </div>
    </header>
  );
};
