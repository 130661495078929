import React, {useState} from "react";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Input, Layout } from "antd";
import { displayErrorMessage } from "../../lib/utils";
import MapboxMap from "./components/MapBox";
import SearchIcon from "./assets/icon.search.svg";
import parisLogo from "./imgs/paris.svg";
import ProviderCard from "lib/components/ProviderCard";
import { PageSkeleton } from "lib/components/PageSkeleton";
import { PROVIDER_COUNT, PROVIDER_MOST_VISITED } from "lib/graphql/queries";
import { ProviderCount, ProviderCountVariables } from "lib/graphql/queries/ProviderCount/__generated__/ProviderCount";
import { ProviderMostVisited, ProviderMostVisitedVariables } from "lib/graphql/queries/ProviderMostVisited/__generated__/ProviderMostVisited";

export const Home = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const { data: data1, loading: loading1 } = useQuery<ProviderCount, ProviderCountVariables>(PROVIDER_COUNT, { variables: { page: 1, limit: 6 }, fetchPolicy: 'no-cache' });
  const { data: data2, loading: loading2 } = useQuery<ProviderMostVisited, ProviderMostVisitedVariables>(PROVIDER_MOST_VISITED, { variables: { page: 1, limit: 4 }, fetchPolicy: 'no-cache' });

  const onSearch = (value: string) => {
    const trimmedValue = value.trim();
    if (trimmedValue) {
      history.push(`/listings/${trimmedValue}`);
    } else {
      displayErrorMessage("Please enter a valid search!");
    }
  };

  return (
    <div className="home-page__body">
      <div className="search__container">
        <h3 className="search__container-title">
          The easy way to drop off humanitarian aid for Ukraine
        </h3>
        <div className="search__container-bar">
        <StyledSearch
          placeholder="Search city"
          value={search}
          onChange={(evt) => setSearch(evt.target.value)}
          onSearch={onSearch}
          allowClear
        />
        </div>
        <div className="search__container-tip">
          <p>
            {" "}
            Frequent in searches:{" "}
            <a href="/listings/lublin" className="search-link">
              {" "}
              Lublin
            </a>
            ,{" "}
            <a href="/listings/berlin" className="search-link">
              Berlin
            </a>
            ,{" "}
            <a href="/listings/london" className="search-link">
              London
            </a>
            ,{" "}
            <a href="/listings/vilnius" className="search-link">
              Vilnius
            </a>
          </p>
        </div>
      </div>
      {(loading1 || loading2) ? (
        <Layout.Content>
          <PageSkeleton />
        </Layout.Content>
      ) : (
        <main className="main">
          <div className="hero__container">
            {data1 && (
              <>
                <h1 className="hero-title">
                  Select the city where you want to find a humanitarian collection
                  points
                </h1>
                <div className="cities__bar">
                  {data1.providerCount.map(provider => (
                    <div className="city__bar-item-wrap" key={provider.city}>
                      <Link to={`/listings/${provider.city}`} className="city__bar-item-link">
                        <div className="city__bar-item">
                          <div className="city__bar-item-img">
                            <img src={parisLogo} alt="1" width={50} height={50} />
                          </div>
                          <div className="city__bar-item-text">
                            <h5 className="city__bar-item-title">{provider.city}</h5>
                            <p className="city__bar-item-points">{provider.cnt_id} points</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </>
            )}

            {data2 && (
              <div className="cards__container">
                <h3 className="most-visited-places">
                  Most visited collecting points
                </h3>
                <div className="collection__cards__grid">
                  {data2.providerMostVisited.map(record => (
                    <ProviderCard
                      key={record.provider.id}
                      id={record.provider.id}
                      title={record.provider.title}
                      address={record.provider.address}
                      avatarImage={record.provider.user.avatar}
                      locationImage={record.provider.image[0]}
                      capacity={record.provider.capacity}
                      count={record.total}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="map__container">
          <h3 className="map__container-title">
            Collection points on DropForUkraine maps
          </h3>
          <div className="home-map-wrapper">
            <MapboxMap type="main" />
          </div>
        </div>
        </main>
      )}
    </div>
  );
};

const StyledSearch = styled(Input.Search)`
  position: relative;
  width: 386px;
  max-width: 100%;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  .ant-input {
    height: auto;
    padding: 10px 48px;
    color: #02020b;
    position: relative;
    background: #ffffff !important;
    border: 1px solid #f2f2f2;
    box-shadow: 0px 13px 10px rgba(50, 50, 71, 0.05),
      0px 22px 28px rgba(50, 50, 71, 0.05);
    border-radius: 500px;

    &::placeholder {
      color: #757575;
    }

    &:focus + .ant-input-suffix {
      display: flex;
    }

    &:hover {
      border-color: #d2e6f7 !important;
    }

    &:focus {
      border-color: #4095da !important;
    }
  }

  .ant-input-suffix {
    right: 20px;
    display: none;

    &:active {
      display: flex !important;
    }
  }

  .ant-input-search-icon {
    display: none;
  }

  &::after {
    content: "";
    display: inline-flex;
    width: 13px;
    height: 13px;
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 22px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
