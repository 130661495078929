import React from "react";
import styled from "styled-components";

interface Props {
  images: string[]
}

export const CalendarCell: React.FC<Props> = ({ images }) => {
  const max = 4;
  const diff = images.length - max;
  const more = diff > 9 ? 9 : diff < 0 ? 0 : diff;
  return (
    <StyledCalendarCell>
      <div className="image-container">
        {images.slice(0, max).map((image, i) => (
          <img
            key={i}
            src={image}
            alt="user-avatar"
          />
        ))}
        {more > 0 && (
          <div className="more"><span>+{more}</span></div>
        )}
      </div>
    </StyledCalendarCell>
  )
};

const StyledCalendarCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; 

  .image-container {
    position: absolute;
    display: flex;
  }

  img {
    width: 40px;
    height: 40px;
    border: 2px solid #F2F2F2;
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: -15px;
    }
  }

  .more {
    width: 40px;
    height: 40px;
    background-color: #F2F2F2;
    border-radius: 50%;
    margin-left: -15px;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #02020B;
  }

`;

export default CalendarCell;
