/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ProviderType {
  DWELLING = "DWELLING",
  INDUSTRIAL = "INDUSTRIAL",
}

export enum ProvidersFilter {
  PRICE_HIGH_TO_LOW = "PRICE_HIGH_TO_LOW",
  PRICE_LOW_TO_HIGH = "PRICE_LOW_TO_HIGH",
}

export interface AddCommentInput {
  id: number;
  comment: string;
}

export interface AddFavoriteInput {
  id: number;
}

export interface ConnectStripeInput {
  code: string;
}

export interface CreateBookingInput {
  id: number;
  checkIn: string;
}

export interface CreateProviderInput {
  title: string;
  description: string;
  image: string[];
  type: ProviderType;
  address: string;
  city: string;
  state: string;
  country: string;
  capacity: number;
  geometry?: Geometry | null;
  working_hours?: ProviderWorkingHoursInput | null;
  aids?: number[] | null;
}

export interface DeleteBookingInput {
  id: number;
}

export interface DeleteCommentInput {
  id: number;
}

export interface DeleteFavoriteInput {
  id: number;
}

export interface DeleteProviderInput {
  id: number;
}

export interface Geometry {
  lat: number;
  lng: number;
}

export interface LogInInput {
  code: string;
}

export interface ProviderWorkingHoursInput {
  mon?: ProviderWorkingHoursTimeInput | null;
  tue?: ProviderWorkingHoursTimeInput | null;
  wed?: ProviderWorkingHoursTimeInput | null;
  thu?: ProviderWorkingHoursTimeInput | null;
  fri?: ProviderWorkingHoursTimeInput | null;
  sat?: ProviderWorkingHoursTimeInput | null;
  sun?: ProviderWorkingHoursTimeInput | null;
}

export interface ProviderWorkingHoursTimeInput {
  from: string;
  to: string;
}

export interface SetProviderRatingInput {
  id: number;
  rating: number;
}

export interface UpdateProviderInput {
  id: number;
  title?: string | null;
  description?: string | null;
  image?: string[] | null;
  type?: ProviderType | null;
  address?: string | null;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  capacity?: number | null;
  geometry?: Geometry | null;
  working_hours?: ProviderWorkingHoursInput | null;
  aids?: number[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
