import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ElipseIcon from "sections/Home/imgs/Ellipse 63.svg";
import PersonIcon from "sections/Home/imgs/Vector (6).svg";
import LikeIcon from "sections/Home/imgs/like-icon.svg";
import SquareIcon from "sections/Home/imgs/icon.area.svg";
import MarkerIcon from "sections/Home/imgs/icon.marker.svg";
import CalendarIcon from "sections/Home/imgs/icon.calendar.2.svg";
import EditIcon from "sections/Home/imgs/icon.edit.svg";
import DeleteIcon from "sections/Home/imgs/icon.delete.svg";

interface Props {
  id: number;
  locationImage: string;
  avatarImage: string;
  title: string
  address: string;
  count: number;
  capacity: number;
  isFavorite?: boolean;
  showCalendar?: boolean;
  showEditable?: boolean;
  onCalendarClick?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onFavoriteClick?: () => void;
}

export const ProviderCard: React.FC<Props> = (p) => {
  const [showEdit, setShowEdit] = useState(false);
  const editRef = useRef<HTMLDivElement | null>(null);

  const prevent = (e: React.MouseEvent<HTMLDivElement>, action: () => void) => {
    e.stopPropagation();
    e.preventDefault();
    action();
  }

  const toggleEdit = () => {
    setShowEdit(prev => !prev);
  }

  const onCalendarClick = () => {
    if (p.onCalendarClick) p.onCalendarClick();
  }

  const onEditClick = () => {
    if (p.onEditClick) p.onEditClick();
    setShowEdit(false);
  }

  const onDeleteClick = () => {
    if (p.onDeleteClick) p.onDeleteClick();
    setShowEdit(false);
  }

  const onFavoriteClick = () => {
    if (p.onFavoriteClick) p.onFavoriteClick();
  }

  return (
    <StyledProviderCard className="collection__cards__link" to={`/listing/${p.id}`}>
      <div className="collection__cards__item">
        <div className="collection__cards__img__wrapper">
          <img src={p.locationImage} alt="location" />
          <div className="count-info">
            {p.isFavorite && (
              <div className="like__wrapper" onClick={e => prevent(e, onFavoriteClick)}>
                <img src={LikeIcon} alt="like-icon" />
              </div>
            )}
            <img src={ElipseIcon} alt="icon-background" />
            <img src={PersonIcon} className="person-icon" alt="person-icon" />
            <div className="count__info-text">{p.count}</div>
          </div>
        </div>
        <div className="collection__cards__text__wrapper">
          <img src={p.avatarImage} alt="user" className="cards-icon" />
          {p.showEditable && (
            <div
              tabIndex={-1}
              ref={editRef}
              onBlur={(e) => !e.currentTarget.contains(e.relatedTarget as any) && setShowEdit(false)}
            >
              <div
                className={`dot-wrapper ${showEdit ? 'active' : ''}`}
                onClick={e => prevent(e, toggleEdit)}
                tabIndex={-1}
              >
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
              <div
                className="edit__container"
                style={{ display: showEdit ? 'flex' : 'none' }}
              >
                <div className="edit" onClick={e => prevent(e, onEditClick)}><span>Edit</span></div>
                <div className="delete" onClick={e => prevent(e, onDeleteClick)}><span>Delete</span></div>
              </div>
            </div>
          )}
          <div>
            <h3 className="cards__item-title">{p.title}</h3>
            <div className="cards__item-text square">{p.capacity}<span>{" "} m<sup>2</sup></span>
            </div>
            <div className="cards__item-text location">{p.address}</div>
          </div>
          {p.showCalendar && (
            <div className="cards__item-btn" onClick={e => prevent(e, onCalendarClick)}>
              <span>View calendar</span>
            </div>
          )}
        </div>
      </div>
    </StyledProviderCard>
  )
};

const StyledProviderCard = styled(Link)`

  img[alt=location] {
    object-fit: cover;
  }

  .collection__cards__link {
    display: flex;
    cursor: pointer;
  }

  .collection__cards__item {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 270px;
    position: relative;
    border: 1px solid #F2F2F2;
    transition: 0.3s linear;
    border-radius: 15px;
    overflow: hidden;
  }

  .collection__cards__img__wrapper {
    height: 120px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .collection__cards__item:hover {
    box-shadow: 0px 16px 16px rgba(50, 50, 71, 0.08), 0px 24px 32px rgba(50, 50, 71, 0.08);
  }

  .cards-icon {
    position: absolute;
    top: -40px;
    left: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid #FFFFFF;
  }

  .count-info {
    position: absolute;
    top: 20px;
    right: 10px;
    padding-right: 10px;
    background-color: #FFFFFFB2;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .like__wrapper {
      position: absolute;
      right: 100%;
      margin-right: 10px;
      width: 24px;
      height: 24px;
      background-color: #FFFFFFB2;
      padding: 4px 3px;
      border-radius: 50%;
      overflow: hidden;

      &:hover {
        background-color: #dfdfdfb1;
      }

      img {
        transform: translateY(-3px);
      }
    }

    .count__info-text {
      margin: 0 0 0 10px;
    }

    .person-icon {
      margin-left: -15px;
    }

  }

  .collection__cards__text__wrapper {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    margin: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cards__item-title {
    font-size: 16px;
    font-weight: 500;
  }

  .cards__item-text {
    font-size: 14px;
    font-weight: 400;
    color: #757575;
    padding: 10px 0 10px 0;
  }

  .collection-points {
    font-size: 15px;
    font-weight: 400;
    color: #757575;
    text-align: center;
    margin-top: 30px;
  }

  .square {
    position: relative;
    margin-left: 25px;
  }

  .square::before {
    content: "";
    background-image: url(${SquareIcon});
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto 0 14px -25px;
  }

  .location {
    position: relative;
    margin-left: 25px;
    padding-top: 0;
  }

  .location::before {
    content: "";
    background-image: url(${MarkerIcon});
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 10px 0 14px -25px;
  }

  .cards__item-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 30px;
    margin: 0 0 20px 0;
    background-color: #EDF5FC;
    border-radius: 100px;
    color: #4095DA;
    position: relative;
    transition: background-color 0.2s linear;

    & > span {
      margin-left: 30px;
      white-space: nowrap;
    }

    &:hover {
      background-color: #d6e9f9;
    }
  }

  .cards__item-btn::before {
    content: "";
    background-image: url(${CalendarIcon});
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto 0 auto 40px;
    bottom: 0;
  }


  .dot-wrapper {
    position: absolute;
    top: 25px;
    right: 10px;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;

    &:hover, &.active {
      background-color: #f1f1f1;
    }

    .dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #757575;

      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }

  .edit__container {
    display: none;
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    right: 10px;
    top: 45px;
    box-shadow: 0px 13px 10px rgba(50, 50, 71, 0.05), 0px 22px 28px rgba(50, 50, 71, 0.05);
    border-radius: 5px;
    flex-direction: column;
    z-index: 10;
  }

  .edit,
  .delete {
    font-weight: 400;
    font-size: 15px;
    color: #757575;
    padding: 5px 20px;
    position: relative;

    &:hover {
      color: #4095DA
    }

    & > span {
      margin-left: 30px;
    }

    &::before, &::after {
      content: "";
      position: absolute;
      background-size: 100% 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      width: 16px;
      height: 16px;
    }
  }

  .edit__container.active {
    right: 20px;
    top: 165px;
    opacity: 1;
  }

  .edit::before {
    background-image: url(${EditIcon});
  }

  .delete::before {
    background-image: url(${DeleteIcon});
  }

`;

export default ProviderCard;
