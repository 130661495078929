import { gql } from "apollo-boost";

export const USER = gql`
  query User($id: Int!) {
    user(id: $id) {
      id
      name
      avatar
      email
      hasWallet
      income
      bookings {
        total
        result {
          id
          date
          provider {
            id
            title
            type
            image
            address
            capacity
          }
        }
      }
      providers {
        total
        result {
          id
          title
          image
          address
          capacity
          bookings {
            total
            result {
              id
              date
              user {
                id
                name
                avatar
              }
            }
          }
        }
      }
      favorites {
        total
        result {
          provider {
            id
            title
            image
            address
            capacity
            user {
              avatar
            }
            bookings(limit: 1, page: 1) {
              total
            }
          }
        }
      }
    }
  }
`;
