import { gql } from "apollo-boost";

export const AIDS = gql`
  query Aids {
    aids {
      id
      parent_id
      position
      depth
      level
      name
    }
  }
`;
