import { gql } from "apollo-boost";

export const PROVIDERS = gql`
  query Providers($location: String $filter: ProvidersFilter!) {
    providers(location: $location, filter: $filter) {
      region
      country
      city
      total
      result {
        id
        title
        image
        address
        capacity
        user {
          avatar
        }
        bookings (page: 1, limit: 1) {
          total
        }
      }
    }
  }
`;
