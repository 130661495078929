import React from "react";
import styled from "styled-components";

export const CardGrid: React.FC = ({ children }) => {
  return (
    <StyledGrid>{children}</StyledGrid>
  )
};

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-auto-rows: 1fr; 
  align-content: stretch;

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

`;

export default CardGrid;
