import React from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Layout, Empty, Alert } from "antd";
import { USER } from "../../lib/graphql/queries";
import { User as UserData, UserVariables } from "../../lib/graphql/queries/User/__generated__/User";
import { PageSkeleton } from "../../lib/components";
import { Viewer } from "../../lib/types";
import { UserBookings, UserListings, UserFavorites } from "./components";

interface Props {
  viewer: Viewer;
}

interface MatchParams {
  id: string;
}

const { Content } = Layout;

export const User = ({ viewer }: Props) => {
  const { id } = useParams<MatchParams>();
  const { data, loading, error, refetch, called } = useQuery<
    UserData,
    UserVariables
  >(USER, {
    variables: { id: +id },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return (
      <Content className="user">
        <PageSkeleton />
      </Content>
    );
  }

  if (error) {
    return (
      <Content className="listings">
        <Alert
          message="Uh oh! Something went wrong :("
          description="This user may not exist or we've encountered an error. Please try again soon."
          type="error"
          showIcon
        />
      </Content>
    );
  }

  if (called && !loading && (data && !data.user)) {
    return (
      <Content className="listings content-center" >
        <Empty description="User is not found" />
      </Content>
    );
  }

  const user = data ? data.user : null;

  const userListings = user ? user.providers : null;
  const userBookings = user ? user.bookings : null;

  const userListingsElement = userListings && user ? (
    <UserListings
      user={user}
      userListings={userListings}
      viewer={viewer}
    />
  ) : null;

  const userBookingsElement = userListings && userBookings ? (
    <UserBookings
      userBookings={userBookings}
      handleRefetch={() => refetch()}
    />
  ) : null;

  const viewerIsUser = viewer.id === +id;

  return (
    <StyledUserProfile>
      <div className="container">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>
          </li>
          <li>
            <Link to={`/user/${user && user.id}`}>Profile</Link>
          </li>
        </ul>
        {userListingsElement}
        {user && (
          <UserFavorites viewerIsUser={viewerIsUser} user={user} onFavoriteDelete={refetch} />
        )}
        {viewerIsUser && userBookingsElement}
      </div>
    </StyledUserProfile>
  );
};

const StyledUserProfile = styled.main`
  .container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .booked__items {
    margin: 0 auto;
    padding: 100px 0;
  }
`;
