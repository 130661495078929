import React from "react";
import styled from "styled-components";

export const Footer = () => (
  <StyledFooter>
    <p className="footer-title">© 2022. DropForUkraine</p>
  </StyledFooter>
);

const StyledFooter = styled.footer`
  width: 100%;
    
  .footer-title {
    text-align: center;
    padding: 50px 0 30px 0;
    color: #757575;
    font-size: 14px;
    font-weight: 400;
  }
`;
