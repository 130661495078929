import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import _groupBy from "lodash.groupby";
import _chunk from "lodash.chunk";
import { useParams, Redirect } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Layout, Input, Empty, Alert } from "antd";
import { PageSkeleton } from "lib/components";
import { PROVIDER } from "lib/graphql/queries";
import { Provider as ListingData, ProviderVariables } from "lib/graphql/queries/Provider/__generated__/Provider";
import { Viewer } from "lib/types";
import MapboxMap from "sections/Home/components/MapBox";
import { displayErrorMessage } from "lib/utils";
import { FormEvent} from "react";
import debounce from 'lodash.debounce';
import { Typography, Form, InputNumber, Radio, Icon, Upload, Button, TimePicker, Checkbox } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { UploadChangeParam } from "antd/lib/upload";
import { ProviderType } from "lib/graphql/globalTypes";
import { iconColor } from "lib/utils";
import { Aids } from "lib/graphql/queries/Aids/__generated__/Aids";
import { AIDS } from "../../lib/graphql/queries";
import { geocode } from "lib/api/map";
import mapboxgl from "mapbox-gl";
import { AID_ICONS } from "constants/aids";
import { UpdateProvider, UpdateProviderVariables } from "lib/graphql/mutations/UpdateProvider/__generated__/UpdateProvider";
import { UPDATE_PROVIDER } from "lib/graphql/mutations";
import { getBase64Value, beforeImageUpload } from "lib/utils";
import moment, { Moment } from "moment";

interface MatchParams {
  id: string;
}

interface Props {
  viewer: Viewer;
  markerState: Common.ReactState<Pick<mapboxgl.LngLat, 'lng' | 'lat'> | null>;
  onAddressChange?: (address: string) => void;
}

const { Content } = Layout;
const { Title } = Typography;
const { Item } = Form;

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const EditForm = ({ viewer, form, markerState }: Omit<Props, "onAddressChange"> & FormComponentProps) => {
  const { id } = useParams<MatchParams>();
  const [isDataReceived, setIsDataReceived] = useState(false);
  const [activeDays, setActiveDays] = useState<Record<string, boolean>>({});
  const [multipleTime, setMultipleTime] = useState<Record<string, { from?: Moment, to?: Moment }>>({});
  const [chosenAids, setChosenAids] = useState<number[]>([]);
  const [imageBase64Value, setImageBase64Value] = useState<string[]>([]);
  const [marker, setMarker] = useMemo(() => markerState, [markerState]);

  const { data: aidsData, loading: aidsLoading, error: aidsError } = useQuery<Aids>(AIDS);

  const { loading: listingLoading, data: listingData, error: listingError, called } = useQuery<ListingData, ProviderVariables>(PROVIDER, {
    variables: { id: +id, commentsPage: 1, limit: 1 }, fetchPolicy: "no-cache"
  });

  const [updateProvider, { data: updateData, loading: updateLoading }] = useMutation<UpdateProvider, UpdateProviderVariables>(UPDATE_PROVIDER, {
    onError: () => displayErrorMessage("Some error occurred while updating listing!"),
  });

  const aids = useMemo(() => {
    if (aidsData && aidsData.aids) {
      return _groupBy(aidsData.aids.filter(a => a.depth !== 3), 'parent_id');
    }
    return {};
  }, [aidsData]);


  useEffect(() => {
    form.setFieldsValue({ image: imageBase64Value.length ? true : null });
  }, [imageBase64Value]); //eslint-disable-line

  useEffect(() => {
      
    const isFormInvalid = Object.keys(activeDays).some(day => {
      if (
        activeDays[day] &&
        (
          !multipleTime[day] || 
          (
            !multipleTime[day].from || !multipleTime[day].to
          )
        )) {
        return true;
      }
      return false;
    });

    if (isFormInvalid) {
      form.setFieldsValue({ working_hours: null });
      return;
    }

    const days = DAYS.reduce((acc, day) => {
      acc[day.toLowerCase()] = activeDays[day] ? {
        from: multipleTime[day].from!.format("HH:mm"),
        to: multipleTime[day].to!.format("HH:mm") 
      } : null;
      return acc;
    }, {} as any);

    form.setFieldsValue({ working_hours: days });

  }, [activeDays, multipleTime]); //eslint-disable-line


  useEffect(() => {
    if (chosenAids.length) {
      form.setFieldsValue({ aids: chosenAids });
      return;
    }

    form.setFieldsValue({ aids: null });
  }, [chosenAids]); //eslint-disable-line

  useEffect(() => {
    if (!isDataReceived || !listingData || !listingData.provider) return;
      const { provider }  = listingData;
      const [address, city, state, zip] = provider.address.split(',').map(v => v.trim());

      form.setFieldsValue({
        type: provider.type,
        title: provider.title,
        description: provider.description,
        capacity: provider.capacity,
        country: provider.country,
        state,
        city,
        address,
        zip,
      });

      setChosenAids(provider.aids.map(aid => aid.details.id));
      setImageBase64Value(provider.image);

      if (provider.working_hours) {
        const hours = Object.keys(provider.working_hours).reduce((acc, day) => {
          const capitalize = day.charAt(0).toUpperCase() + day.slice(1);
          const time = (provider.working_hours as any)[day];
          acc[capitalize] = time ? { from: moment(time.from, "HH:mm"), to: moment(time.to, "HH:mm") } : null;
          return acc;
        }, {} as any);
        const days = Object.keys(provider.working_hours).reduce((acc, day) => {
          const capitalize = day.charAt(0).toUpperCase() + day.slice(1);
          acc[capitalize] = !!(provider.working_hours as any)[day];
          return acc;
        }, {} as Record<string, boolean>);
        setMultipleTime(hours);
        setActiveDays(days);
      }

  }, [isDataReceived]); //eslint-disable-line

  useEffect(() => {
    if (listingData && listingData.provider) setIsDataReceived(true);
  }, [listingData])

  if (updateData && updateData.updateProvider) {
    return <Redirect to={`/listing/${id}`} />;
  }

  if (
    (aidsLoading && !aidsData) ||
    (updateLoading && !updateData) ||
    (listingLoading && !listingData)
  ) {
    return (
      <Content className="listings">
        <PageSkeleton />
      </Content>
    );
  }

  if (listingError || aidsError) {
    return (
      <Content className="listings">
        <Alert
          message="Uh oh! Something went wrong :("
          description="We've encountered an error. Please try again soon!"
          type="error"
          showIcon
        />
      </Content>
    );
  }

  const listing = listingData ? listingData.provider : null;
  const viewerIsHost = viewer.id === (listingData && listingData.provider && listingData.provider.user.id);

  if (called && !listingLoading && !listing) {
    return (
      <Content className="listings content-center" >
        <Empty description="Provider is not found" />
      </Content>
    );
  }

  if (!viewerIsHost) {
    return (
      <Content className="listings">
        <Alert
          message="Uh oh! Something went wrong :("
          description="You can edit only your own listing!"
          type="error"
          showIcon
        />
      </Content>
    );
  }

  if (!listing) {
    return null;
  }


  const handleImageUpload = (info: UploadChangeParam) => {
    const { file } = info;
    if (file.status === "uploading") {
      return;
    }

    if (file.status === "done" && file.originFileObj) {
      getBase64Value(file.originFileObj, (imageBase64Value: string) => {
        setImageBase64Value(prev => [...prev, imageBase64Value]);
      });
    }

    form.setFieldsValue({ image: file });
  };

  const handleValidation = (action: (values: any) => void): void => {
    form.validateFields((error, values) => {
      if (error) {
        displayErrorMessage("Please complete all required form fields!");
        return;
      }
      if (!marker) {
        displayErrorMessage("Address could not be found on the map. Please try to enter another address!");
        return;
      }
      action(values);
    });
  }

  const handleHostListing = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    handleValidation(values => {
      const fullAddress = `${values.address}, ${values.city}, ${values.state}, ${values.zip}`;
      const input = {
        ...values,
        address: fullAddress,
        image: imageBase64Value,
        geometry: marker,
        capacity: +values.capacity,
        id: listing.id
      };

      delete input.zip;

      updateProvider({ variables: { input } });
    });
  };

  return (
    <Content className="host-content">
      <div>

        <div className="host__form-header">
          <Title level={3} className="host__form-title">
            Update your listing
          </Title>
        </div>
        <Form layout="vertical" onSubmit={handleHostListing}>

          <Item label="Building Type">
            {form.getFieldDecorator("type", {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message: "Please select a home type!",
                },
              ],
            })(
              <Radio.Group>
                <Radio.Button value={ProviderType.INDUSTRIAL}>
                  <Icon type="bank" style={{ color: iconColor }} />{" "}
                  <span>Industrial</span>
                </Radio.Button>
                <Radio.Button value={ProviderType.DWELLING}>
                  <Icon type="home" style={{ color: iconColor }} />{" "}
                  <span>Dwelling</span>
                </Radio.Button>
              </Radio.Group>
            )}
          </Item>

          <Item label="Title" extra="Max character count of 55" htmlFor="title">
            {form.getFieldDecorator("title", {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message: "Please enter a title for your listing!",
                },
              ],
            })(
              <Input
                name="title"
                maxLength={55}
                placeholder="New London Metal - metal manufacturing company"
              />
            )}
          </Item>

          <Item
            label="Description"
            extra="Max character count of 400"
            htmlFor="description"
          >
            {form.getFieldDecorator("description", {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message: "Please enter a description for your listing!",
                },
              ],
            })(
              <Input.TextArea
                name="description"
                rows={3}
                maxLength={400}
                placeholder="We feel it's our duty to provide our industrial capabilities to help Ukrainian people as they were our fellow citizens."
              />
            )}
          </Item>

          <Item
            label="Capacity"
          >
            {form.getFieldDecorator("capacity", {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message: "Please enter a capacity!",
                },
              ],
            })(<InputNumber min={0} placeholder="120" />)}
          </Item>

          <Item label="Country" htmlFor="country">
            {form.getFieldDecorator("country", {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message: "Please enter a country for your provider!",
                },
              ],
            })(
              <Input
                placeholder="United Kingdom"
                name="country"
                autoComplete="address-level3"
              />
            )}
          </Item>

          <Item label="State/Province" htmlFor="state">
            {form.getFieldDecorator("state", {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message: "Please enter a state (or province) for your listing!",
                },
              ],
            })(
              <Input
                placeholder="United Kingdom"
                name="state"
                autoComplete="address-level1"
              />
            )}
          </Item>

          <Item label="City/Town" htmlFor="city">
            {form.getFieldDecorator("city", {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message: "Please enter a city (or region) for your provider!",
                },
              ],
            })(
              <Input
                placeholder="London"
                name="city"
                autoComplete="address-level2"
              />
            )}
          </Item>

          <Item label="Address" htmlFor="address">
            {form.getFieldDecorator("address", {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message: "Please enter an address for your listing!",
                },
              ],
            })(
              <Input
                placeholder="9766 St. John’s Road"
                name="address"
                autoComplete="street-address"
              />
            )}
          </Item>

          <Item label="Zip/Postal Code" htmlFor="zip">
            {form.getFieldDecorator("zip", {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message:
                    "Please enter a zip (or postal) code for your listing!",
                },
              ],
            })(
              <Input
                placeholder="Please enter a zip code for your listing!"
                autoComplete="postal-code"
                name="zip"
              />
            )}
          </Item>

          <Item label="Address position">
            <MapboxMap
              type="marker" 
              markerPos={marker}
              onMarkerPosChange={e => setMarker(e)}
              style={{ aspectRatio: '16/9' }}
            />
          </Item>

          <Item label="Working hours" htmlFor="working_hours">
            {form.getFieldDecorator("working_hours", {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message:
                    "Please enter working hours!",
                },
              ] ,
            })(
              <>
                <StyledHours>
                  <>
                  {DAYS.map(day => (
                    <div className="multiple-item" key={day}>
                      <Button
                        className="active-day"
                        key={day}
                        type={ activeDays[day] ? 'primary' : 'default' }
                        onClick={() => setActiveDays(prev => ({ ...prev, [day]: !activeDays[day] }))}
                      >
                        {day}
                      </Button>
                      <div className="time">
                        <TimePicker
                          format="HH:mm"
                          disabled={!activeDays[day]}
                          value={multipleTime[day] && multipleTime[day].from}
                          minuteStep={5}
                          onChange={t => setMultipleTime(prev => ({ ...prev, [day]: { ...multipleTime[day], from: t, to: t } }))}
                        />
                        <div className="divider"></div>
                        <TimePicker
                          format="HH:mm"
                          disabled={!activeDays[day]}
                          value={multipleTime[day] && multipleTime[day].to}
                          minuteStep={5}
                          onChange={t => setMultipleTime(prev => ({ ...prev, [day]: { ...multipleTime[day], to: t } }))}
                          disabledHours={() => {
                            if (multipleTime[day] && multipleTime[day].from) {
                              return Array.from(Array(24).keys()).filter(hour => hour < multipleTime[day].from!.hour());
                            }
                            return [];
                          }}
                          disabledMinutes={hour => {
                            if (
                              multipleTime[day] &&
                              multipleTime[day].from &&
                              multipleTime[day].from!.hour() === hour
                            ) {
                              return Array.from(Array(60).keys()).filter(hour => hour < multipleTime[day].from!.minute());
                            }
                            return [];
                          }}
                        />
                      </div>
                    </div>
    
                  ))}
                  </>
                </StyledHours>
              </>
            )}
          </Item>

          <Item label="Humanitarian things that you are willing to accept" htmlFor="aids">
            {form.getFieldDecorator("aids", {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                {
                  required: true,
                  message: "Please choose some humanitarian things that you are willing to accept!",
                },
              ],
            })(
              <>
                <StyledAids>
                  {aids['null'].map(aid => (
                    <div key={aid.id} className="aids-group">
                      <Checkbox
                        className="aid-group-item"
                        checked={aids[aid.id].every(aid => chosenAids.includes(aid.id))}
                        onChange={e => setChosenAids(prev => {
                          const ids = aids[aid.id].map(aid => aid.id);
                          if (e.target.checked) {
                            const newArr = [...prev, ...ids];
                            return newArr.filter((v, i, a) => a.indexOf(v) === i); 
                          } else {

                          }
                          return [...prev.filter(id => !ids.includes(id))];
                        })}
                      >
                        <span className="text">{aid.name}</span>
                      </Checkbox>
                        <div className="aids-row">
                          {_chunk(aids[aid.id], 6).map((chunk, i) => (
                            <div key={i} className="aids-column">
                                {chunk.map(aid => (
                                  <Checkbox
                                    key={aid.id}
                                    checked={chosenAids.includes(aid.id)}
                                    onChange={e => setChosenAids(prev => {
                                      if (e.target.checked) {
                                        return [...prev, aid.id];
                                      }
                                      return [...prev.filter(id => id !== aid.id)];
                                    })}
                                  >
                                    <div className="aid-item">
                                      <div className="icon">
                                        <img src={AID_ICONS[aid.id as keyof typeof AID_ICONS]} alt="1" />
                                      </div>
                                      <span>{aid.name}</span>
                                    </div>
                                  </Checkbox>
                                ))}
                            </div>
                          ))}
                        </div>
                    </div>
                ))}
                </StyledAids>
              </>
            )}
          </Item>

          <Item label="Photo">
            <div className="host__form-image-upload">
              {form.getFieldDecorator("image", {
                validateTrigger: ['onChange', 'onBlur'],
                valuePropName: "upload",
                rules: [
                  {
                    required: true,
                    message: "Please provide an image for your listing!",
                  },
                ],
              })(
                <>
                  <StyledImages>
                    {imageBase64Value.map((image, i) => (
                      <div key={i} className="image-wrap">
                        <img src={image} alt="1" className="image" />
                        <div className="close" onClick={() => {
                          setImageBase64Value(prev => prev.filter((_, index) => index !== i));
                        }}>
                          <Icon type="close" />
                        </div>
                      </div>
                    ))}
                  </StyledImages>
                  <Upload.Dragger
                    name="image"
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={beforeImageUpload}
                    onChange={handleImageUpload}
                    customRequest={({ file, onSuccess }) => setTimeout(() => onSuccess({}, file), 0)}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">Drop your image here, or browse</p>
                    <p className="ant-upload-hint">Supports: JPG, PNG (images have to be under 5 Mb in size)</p>
                  </Upload.Dragger>
                </>

              )}
            </div>
          </Item>

          <Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Item>

        </Form>
      </div>
    </Content>
  );
};

export const WrappedEditForm = Form.create<Props & FormComponentProps>({
  name: "edit_form",
  onFieldsChange: (props) => {
    const { onAddressChange, form } = props;
    const { country, city, state, address, zip } = form.getFieldsValue();
    if (country && address && city && state && zip && onAddressChange) {
      const fullAddress = `${country}, ${city}, ${state}, ${address}, ${zip}`;
      onAddressChange(fullAddress);
    }
  }
})(EditForm);

export const Edit: React.FC<Omit<Props, "markerState" | "onAddressChange"> & Omit<FormComponentProps, "form">> = (props) => {
  const [marker, setMarker] = useState<Pick<mapboxgl.LngLat, 'lng' | 'lat'> | null>(null);
  const [address, setAddress] = useState("");

  const updateMarker = useMemo(() => (
    debounce(async (address: string) => {
      if (address) {
        const g = await geocode(address);
        const location = g.results[0] ? g.results[0].geometry.location : null;
        setMarker(location);
      }
    }, 1000)
  ), []);

  useEffect(() => {
    return () => {
      updateMarker.cancel();
    }
  }, [updateMarker]);

  useEffect(() => {
    updateMarker(address);
  }, [address, updateMarker]);

  return <WrappedEditForm {...props} onAddressChange={a => setAddress(a)} markerState={[marker, setMarker]} />
}

const StyledImages = styled.div`
  
  .image-wrap {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    margin-right: 20px;
    display: inline-block;

    .close {
      position: absolute;
      right: 5px;
      top: 5px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 2px;
      cursor: pointer;

      &:hover {
        background-color: #0000002d;
      }
    }
  }

  .image {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }

`;

const StyledHours = styled.div`

  margin-top: 20px;

  .active-day {
    width: 100px;
  }

  .time {
    display: flex;
    align-items: center;
  }

  .divider {
    width: 15px;
    height: 1px;
    background-color: #02020B;
    margin: 0 10px;
  }

  .multiple-item {
    display: flex;
    align-items: center;

    & > *:first-child {
      margin-right: 30px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

`;

const StyledAids = styled.div`

  .ant-checkbox-wrapper {
    margin: 0;
    display: inline-flex;
    align-items: center;
    padding: 5px 0;
  }

  .aids-group {
    margin-top: 40px;
  }

  .aids-row {
    display: flex;
    flex-wrap: wrap;
  }

  .aids-column {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    width: 320px;
  }

  .aid-item {

    display: inline-flex;
    align-items: center;
    font-family: 'Rubik';
    font-size: 14px;
    color: #757575;

    .icon {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
    

  }

  .aid-group-item {
    margin-bottom: 20px;
    font-family: 'Rubik';
    font-size: 14px;
    color: #02020B;

    .text {
      margin-left: 10px;
    }
  }

`;
