import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Layout, Alert, Empty } from "antd";
import { PROVIDERS } from "lib/graphql/queries";
import { Providers as ListingsData, ProvidersVariables } from "lib/graphql/queries/Providers/__generated__/Providers";
import { ProvidersFilter } from "lib/graphql/globalTypes";
import { ListingsFilters, ListingsSkeleton } from "./components";
import MapboxMap from "sections/Home/components/MapBox";
import PlusIcon from "sections/Home/imgs/plus-icon.svg"
import ProviderCard from "lib/components/ProviderCard";
import CardGrid from "lib/components/CardGrid";

interface MatchParams {
  location: string;
}

const { Content } = Layout;

export const Listings = () => {
  const { location } = useParams<MatchParams>();
  const locationRef = useRef(location);
  const [filter, setFilter] = useState(ProvidersFilter.PRICE_LOW_TO_HIGH);
  const { loading, data, error, called } = useQuery<ListingsData, ProvidersVariables>(
    PROVIDERS,
    {
      variables: { location, filter },
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  if (loading && !data) {
    return (
      <Content className="listings">
        <ListingsSkeleton />
      </Content>
    );
  }

  if (error) {
    return (
      <Content className="listings">
        <Alert
          message="Uh oh! Something went wrong :("
          description="We've encountered an error. Please try again soon!"
          type="error"
          showIcon
        />
      </Content>
    );
  }

  const listings = data ? data.providers : null;

  if (called && !loading && (listings && !listings.total)) {
    return (
      <Content className="listings content-center" >
        <Empty
          style={{ maxWidth: 400 }}
          description={`
            We couldn't find anything matching your search. 
            If you're searching for a unique location, try searching again with more common keywords
          `}
        />
      </Content>
    );
  }

  return (
    <StyledListings>
      <div className="container">
        <ul className="breadcrumb">
          <li><Link to="/">Home</Link></li>
          <li>
          <Link to={`/listings/${location}`} className="active">
            Collection points in&nbsp;
            {listings && listings.country}
            {listings && listings.city ? ": " + listings.city : ""}
          </Link>
          </li>
        </ul>
        <div className="map__container">
          <h1 className="map__container-title">
              Collection points in&nbsp;
              {listings && listings.country}
              {listings && listings.city ? ": " + listings.city : ""}
          </h1>
          <div className="map__container-map">
            {data && data.providers && (
              <MapboxMap type="items" itemsFilter={{ country: data.providers.country, city: data.providers.city }} />
            )}
          </div>
        </div>
        <div className="filter__container">
          {/* <div className="filter">
            <div>Filter by type: </div>
            <Select
              className="sort-list"
              value="Industrial"
              disabled={true}
            >
              <Select.Option value="Industrial">
                Industrial
              </Select.Option>
              <Select.Option value="Dwelling">
                Dwelling
              </Select.Option>
            </Select>
          </div> */}
          <ListingsFilters filter={filter} setFilter={setFilter} />
        </div>
        <div className="collection__cards__container">
          <div className="title__wrap">
            <h2 className="collection__cards__container-title">
              {listings && listings.result.length} results in {listings && (listings.city ? listings.city : listings.country)}
            </h2>
          </div>
          <CardGrid>
            {listings && listings.result.length > 0 && (
              listings.result.map(listing => (
                <ProviderCard   
                  key={listing.id}
                  id={listing.id}
                  address={listing.address}
                  capacity={listing.capacity}
                  count={(listing.bookings && listing.bookings.total) || 0}
                  title={listing.title}
                  locationImage={listing.image[0]}
                  avatarImage={listing.user.avatar}
                />
              ))
            )}
          </CardGrid>
        </div>
      </div>
    </StyledListings>
  );
};

const StyledListings = styled.main`

  .container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .collection__button {
    width: 100%;
    max-width: 215px;
    max-height: 40px;
    border-radius: 100px;
    background-color: #4095DA;
    margin-left: 15px;
  }

  .collection__button:hover {
    background-color: #000;
    transition: 0.3s linear;
  }

  .collection__button-link {
    width: 100%;
  }

  .collection__button-text {
    padding: 10px 20px 10px 40px;
    text-align: center;
    color: #fff;
    position: relative;
  }

  .collection__button-text::before {
    content: "";
    background-image: url(${PlusIcon});
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto 0 15px 20px;
  }

  .map__container-title {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
  }

  .filter__container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter,
  .sort {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
    align-items: center;
  }

  .sort-list,
  .filter-list {
    margin-left: 40px;
    border: none;
    font-size: 14px;
    font-weight: 500;
  }

  .collection__cards__container {
    margin-top: 60px;
  }

  .collection__cards__container-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 30px;
  }

`;